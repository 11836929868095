@import '../App/colors.scss';

.UserItemList {
    width:100%;
    background-color:white;
    margin:0.5em;
}

.UserItem {
    display:grid;
    grid-template-columns: 10.0em 5.0em 7.0em 1fr 8.0em;
    grid-column-gap: 2px;
    align-items: center;
    padding-bottom:4px;
    position: relative;
}

.UserItem .TypeHeading {
    text-align: left;
    background-color: $list-header-color;
    height:2.0em;
    padding-top:0.3em;
    padding-left:0.5em;
    font-weight:bold;
    white-space: nowrap;
    overflow: hidden;
}

.UserItem .StatusHeading {
    text-align: left;
    background-color: $list-header-color;
    height:2.0em;
    padding-top:0.3em;
    padding-left:0.5em;
    font-weight:bold;
    white-space: nowrap;
    overflow: hidden;
}

.UserItem .CreatedAtHeading {
    text-align: left;
    background-color: $list-header-color;
    height:2.0em;
    padding-top:0.3em;
    padding-left:0.5em;
    font-weight:bold;
    white-space: nowrap;
    overflow: hidden;
    border-top-left-radius: 5px;
}

.UserItem .DescriptionHeading {
    text-align: left;
    background-color: $list-header-color;
    height:2.0em;
    padding-top:0.3em;
    padding-left:0.5em;
    font-weight:bold;
    white-space: nowrap;
    overflow: hidden;
}

.UserItem .OperationsHeading {
    text-align: center;
    border-top-right-radius: 5px;
    background-color: $list-header-color;
    font-weight:bold;
    padding-top:0.3em;
    height:2.0em;
}

.UserItem .Operations {
    display:grid;
    padding-left:2.5em;
    padding-top:0.5em;
    grid-template-columns: 1.0em 1.0em;
    grid-column-gap: 10px;
    font-weight:bold;
    height:1.5em;
    border: 1px solid lightgray;
    background-color: white;
    height:100%;
}

.UserItem .Type {
    text-align: center;
    border: 1px solid lightgray;
    height:100%;
    padding-top:0.3em;
}

.UserItem .Status {
    text-align: center;
    border: 1px solid lightgray;
    height:100%;
    padding-top:0.3em;
}

.UserItem .CreatedAt {
    border: 1px solid lightgray;
    width:100%;
    height:100%;
    text-align: center;
    justify-content: center;
    padding-top:0.3em;
}

.UserItem .Description {
    padding-left:0.5em;
    padding-top:0.3em;
    border: 1px solid lightgray;
    height:100%;
}

.UserItem .Operations .Edit {
    cursor: pointer;
}

.UserItem .Operations .Remove {
    color:red;
    cursor: pointer;
}

.EditUserItem {
    display:grid;
    grid-template-columns: 9em 1fr 8em;
    grid-column-gap: 2px;
    grid-row-gap: 3px;
    padding:1em;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 5px;
    align-items: center;
    border: 1px solid lightgray;
    margin-bottom: 0.5em;
    position: relative;
}

.EditUserItem .Operations .Remove {
    color:red;
    cursor: pointer;
}

.EditUserItem h4 {
    margin:0px;
    grid-column-start: 1;
    grid-column-end: 3;
    padding-bottom:15px;
}

.EditUserItem .Label {
    font-weight:bold;
    margin:0px;
    padding:0px;
    user-select: none;
}

.EditUserItem .Date {
    margin-top:0px;
    padding-top:0px;
    max-height:1.0em;
    border: 0px solid lightgray;
}

.EditUserItem .Date input {
    padding:0px;
    box-shadow: none;
    line-height: 0em;
    margin:0em;
    border-radius:0px;
    padding-left:0.5em;
    max-width: 10em;
    border: 1px solid lightgray;
    max-height: 1.5em;
}

.EditUserItem .Reminders {
    display:grid;
    grid-template-columns:150px 30px;
}

.EditUserItem .ReminderRemove {
    cursor: pointer;
}

.EditUserItem .Interval {
    grid-template-columns: 3.0em 10.0em;
    display:grid;
}

.EditUserItem .NumberList {
    display:grid;
    grid-template-columns: 10em 2em;
    align-items: center;
    margin-top:2px;
    margin-bottom:2px;
}

.EditUserItem .Repeating {
    grid-template-columns: 2.0em 10.0em;
    grid-row-gap: 2px;
    display:grid;
    margin-bottom:2px;
}

.EditUserItem .DateEraser .Erase {
    transform: translate(-50%, -50%);
}

.EditUserItem .Mediums {
    grid-template-columns: 1.2em 1fr;
    grid-row-gap: 1px;
    display:grid;
    margin-bottom:0.4em;
}

.EditUserItem .Operations {
    grid-column: 3;
    grid-row-start: 1;
    grid-row-end: 15;
    display:grid;
    padding-left:1.7em;
    padding-top:0.2em;
    padding-right:0.0em;
    padding-bottom:0.2em;
    grid-template-columns: 1.0em 1.0em;
    grid-column-gap: 10px;
    font-weight:bold;
    height:1.5em;
}
