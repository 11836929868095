.NormMaterialEditor {
    position: relative;
}

.NormMaterialEditor .Type {
    display:grid;
    grid-template-columns: 5em 10em;
}

.NormMaterialEditor .BasicInfo {
    margin-top:2px;
    margin-bottom:2px;
    display:grid;
    grid-template-columns: 10em 1fr;
    margin-right:12px;
}

.NormMaterialEditor .BasicInfo .Organization {
    margin-top:2px;
    margin-bottom:2px;
    display:grid;
    grid-template-columns: 20em 5em;
    column-gap: 4px;
    margin-right:12px;
}

.NormMaterialEditor .Type .Label {
   font-style:italic;
}

.NormMaterialEditor .BasicInfo .Label {
    font-style:italic;
    padding-top:0.5em;
 }

.NormMaterialEditor .AddOrganization {
    text-align: center;
    font-weight: normal;
    color: black;
    border-radius: 0px;
    font-size:1.0em;
    background-color:#cfcfcf;;
    align-self: top;
    padding-left:5px;
    padding-right:5px;
    margin-right:2px;
    user-select: none;
    border-radius:5px;
}

.NormMaterialEditor .ItemEven {
    padding-left:5px;
    padding-right:5px;
    padding-top:2px;
    padding-bottom:4px;
    margin-right:10px;
    margin-bottom:4px;
    background-color:#fafafa;
    border-radius:5px;
    position: relative;
}

.NormMaterialEditor .ItemOdd {
    padding-left:5px;
    padding-right:5px;
    padding-top:2px;
    padding-bottom:4px;
    margin-right:10px;
    margin-bottom:4px;
    background-color:#f7f7f7;
    border-radius:5px;
    position: relative;
}

.NormMaterialEditor .Delete {
    position:absolute;
    top: 10px;
    right: 15px;
    cursor: pointer;
    color:red;
}