.CreateOrganizationDropDown {
    color:black;
    position:absolute;
    left:-4px;
    top:-4px;
}

.CreateOrganizationDropDown .Toggle {
    position: absolute;
    z-index:6;
    background-color:white;
    padding:4px;
    width:4.0em;
    height:2.0em;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    color:black;
    border-left: 1px solid black;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
}

.CreateOrganizationDropDown .Overlap {
    background-color:white;
    position:absolute;
    top:0px;
    left:3.0em;
    height:1.85em;
    width:1.0em;
    z-index:6;
}

.CreateOrganizationDropDown .ContentHolder {
    cursor: pointer;
    position: absolute;
    z-index:5;
    background-color:white;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    width:15.0em;
    padding:3px;
    display:grid;
    grid-row-gap: 2px;
    top:0.0em;
    left:4.0em;
    border: 1px solid black;
}