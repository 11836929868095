@import '../../App/colors.scss';

div.LibrarySelector {
    position:fixed;
    z-index:101;
    transform: translate(-50%,-50%);
    top:50%;
    left:50%;
    background-color: white;
    box-shadow: 3px 3px 5px 6px rgba(0,0,0,0.2);
    min-width:400px;
    min-height:200px;
    padding:0.0em;
    margin:5px;
    border-radius: 1.0em;
    max-height:90%;
    min-height:90%;
    min-width:80%;
    max-width:80%;
    grid-template-rows: 2.5em 1fr 2em;
    display:grid;
}

div.LibrarySelector .TopBar {
    display:grid;
    grid-template-columns: 2.5em 20em;
    margin-bottom:20px;
    margin-left:15px;
    margin-top:15px;
    align-items: center;
    color:black;
}

div.LibrarySelector .TopBar span {
    font-weight:bold;
}

div.LibrarySelector .BottomBar {
    padding-right:10px;
    margin-right:0px;
    margin-top:-10px;
    margin-bottom:5px;
    width:100%;
    display:grid;
    grid-template-columns:1fr 6em 9em;
    column-gap: 5px;
}

div.LibrarySelector .ContentBar {
    grid-template-columns:50% 50%;
    padding-top:5px;
    padding-bottom:5px;
    display:grid;
    margin:10px;
    overflow: hidden;
}

div.LibrarySelector .ContentColumn {
    overflow-y: scroll;
}

div.LibrarySelector .ContentColumn .Item {
    display:grid;
    background-color:#efefef;
    border-radius:7px;
    grid-template-columns: 1fr 6em;
    margin:2px 0px 0px 5px;
    padding:2px 6px 2px 6px;
}

div.LibrarySelector .ContentColumn .ActionButton {
    font-weight: normal;
    color: black;
    border-radius: 2px;
    background-color:rgb(150, 202, 219);
    margin:0.2em;
    font-size:1.0em;
    min-width:80px;
    font-weight:500;
    outline: none;
}


div.LibrarySelector .ContentColumn .Circle {
    display:grid;
    grid-template-columns: 1fr 6em;
    align-items: center;
    background-color:#dfdfdf;
    border-radius:5px;
    padding-right:5px;
    margin:1px;
}

div.LibrarySelector .ContentColumn .Circle .HeaderName {
    font-size:1.2em;
    font-weight:bold;
    margin:3px;
    padding-left:7px;
    padding-top:2px;
    padding-bottom:2px;
}

div.LibrarySelector .ContentColumn .Content {
    margin-left:0.5em;
    padding:0px;
}

div.LibrarySelector .Content .Search {
    min-width:20em;
}

div.LibrarySelector .ContentColumn .HeaderText {
    color:black;
    font-weight:bold;
    margin-left:5px;

}

div.LibrarySelector .CloseButton {
    font-weight: normal;
    color: black;
    border-radius: 5px;
    background-color:lightgreen;
    margin:0.2em;
    font-size:1.1em;
    min-width: 80px;
    font-weight:bold;
    outline: none;
}

div.LibrarySelector .AddButton {
    font-weight: normal;
    color: black;
    border-radius: 5px;
    background-color:$list-header-color;
    margin:0.2em;
    font-size:1.1em;
    min-width:80px;
    font-weight:bold;
    outline: none;
}


div.LibrarySelector .ContentColumn .ItemSelected {
    display:grid;
    background-color:#efffef;
    border-radius:7px;
    grid-template-columns: 1fr 6em;
    margin:2px 0px 0px 5px;
    padding:2px 6px 2px 6px;
}

div.LibrarySelector .ContentColumn .CircleSelected {
    display:grid;
    grid-template-columns: 1fr 6em;
    align-items: center;
    background-color:#dfefdf;
    border-radius:5px;
    padding-right:5px;
    margin:1px;
}

div.LibrarySelector .ContentColumn .CircleSelected .HeaderName {
    font-size:1.2em;
    font-weight:bold;
    margin:3px;
    padding-left:7px;
    padding-top:2px;
    padding-bottom:2px;
}