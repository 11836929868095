.TextAreaStatic {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 2.0em;
    cursor: pointer;
}

.TextAreaStatic .PlaceHolder {
    color: grey;
}

.NumberEdit {
    border: 1px solid lightgray;
    padding-bottom:0px;
    margin-bottom:0px;
    padding-left:0.5em;
    margin-right:0.1em;
    max-width:5.0em;
}


.NumberEditLimits {
    display:flex;
    flex-direction: column;
}