@import '../App/colors.scss';

.ReplyContainer {
    display:flex;
    align-items: stretch;
    position:relative;
}

.TextHeading {
    border-top-left-radius: 0.5em;
    padding-left:0.5em;
    margin-right:0.0em;
    background-color: $list-header-color;
    border-color:#ffffff;
    margin-right:0.1em;
    font-weight:bold;
    flex:1;
    position: relative;
    
}

.StartRow {
    grid-column-start: 1;
}

.TextHeadingFull {
    border-top-left-radius: 0.5em;
    padding-left:0.5em;
    padding-right:0.0em;
    background-color: $list-header-color;
    border-color:#ffffff;
    margin-right:0.1em;
    font-weight:bold;
    flex:2;
    grid-column-start: 1;
    grid-column-end: 3;
    position: relative;
    margin-top:0.2em;
}

.SubQuestionareHeading {
    background-color: #e5bfff;
    margin-top:15px;
    margin-bottom:10px;
    font-size:1.0em;
    grid-column-start: 1;
    grid-column-end: 3;
}

.EmptyText {
    padding-left:0.5em;
    margin-right:0.0em;
    background-color: $list-header-color;
    border-color:#ffffff;
    margin-right:0.1em;
    font-weight:bold;
    flex:1;
    position: relative;
}

.MinMax {
    margin-top:0px;
    padding-top:0px;
    padding-bottom:0px;
    font-size:0.8em;
    font-style: italic;
    text-transform: lowercase;
    display:flex;
    justify-content: flex-end;
}

.MinMax .Min {
    margin:0px;
    padding-right:5px;
    text-align: right;
    white-space: nowrap; 
}

.MinMax .Max {
    margin:0px;
    padding-left:5px;
    text-align: left;
    white-space: nowrap; 
}

.Heading {
    border-radius:0.5em;
    margin-right:0.1em;
    text-align: center;
    flex:1;
}

.KaHeading {
    background-color: $list-header-color;
    border-top-right-radius:0.5em;
    border-bottom-right-radius:0.5em;
    margin-right:0.1em;
    text-align: center;
    flex:1;
}