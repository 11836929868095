@import '../App/colors.scss';

.Paging {
    grid-column-start: 1;
    grid-column-end: 7;
    display:grid;
    grid-template-columns: 2.0em 8.0em 2.0em;
    align-items: center;
    justify-items: center;
    justify-content: center;
    width: 100%;
    min-height: 1.8em;
    background-color: $list-header-color;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    margin-top:2px;
}

.Paging .PagingButton {
    font-size:1.2em;
    cursor: pointer;
}