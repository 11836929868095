@import '../App/colors.scss';

.TemplateList {
    margin-bottom:2.0em;
    width:100%;
    max-width:45em;
}

.Template {
    display:-ms-grid;
    display:grid;
    -ms-grid-columns: 1fr 8.0em;
    grid-template-columns: 1fr 8.0em;
    grid-column-gap: 2px;
    align-items: center;
    padding-bottom:4px;
}

.Template .NameHeading {
    text-align: left;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 2px;
    background-color: $list-header-color;
    height:2.0em;
    padding-left:0.5em;
    padding-top:0.3em;
    font-weight:bold;
}

.Template .Name {
    text-align: left;
    border: 1px solid lightgray;
    padding-left:0.5em;
}

.Template .GroupHeading {
    text-align: left;
    background-color: $list-header-color;
    height:2.0em;
    padding-top:0.3em;
    padding-left:0.5em;
    font-weight:bold;
}

.Template .Group {
    text-align: left;
    border: 1px solid lightgray;
    padding-left:0.5em;
}

.Template .NewGroup {
    grid-column-start: 1;
    grid-column-end: 3;
    text-align: left;
    border: 1px solid lightgray;
}

.Template .OperationsHeading {
    text-align: center;
    border-top-right-radius: 5px;
    background-color: $list-header-color;
    font-weight:bold;
    padding-top:0.3em;
    height:2.0em;
}

.Template .Operations {
    display:grid;
    padding-left:2.5em;
    padding-top:0.2em;
    grid-template-columns: 1.0em 1.0em;
    grid-column-gap: 10px;
    font-weight:bold;
    height:1.5em;
    border: 1px solid lightgray;
}

.Template .Operations .Edit {
    cursor: pointer;
}

.Template .Operations .Remove {
    color:red;
    cursor: pointer;
}


.Template .AddTemplate {
    margin-top:0.5em;
    color: black;
    border-radius: 10px;
    font-size:0.9em;
    padding-left:8px;
    padding-right:8px;
    padding-top:3px;
    padding-bottom:3px;
    background-color:$list-header-color;
    border: 0px solid white;
}

.Template .Label {
    font-size: 0.8em;
    font-style:italic;
}

.Template .NameLabel {
    grid-column-start: 1;
    grid-column-end: 3;
    font-size: 0.8em;
    font-style:italic;
}

.Template .NameNew {
    grid-column: 1;
    grid-column-start: 1;
    grid-column-end: 2;
    border: 1px solid lightgray;
}

.Template input {
    width:100%;
    border: 0px none lightgray;
}

.TemplateList .CreateButton {
    text-align: center;
    font-weight: normal;
    color: white;
    border-radius: 0px;
    font-size:1.0em;
    background-color:green;
    padding:2px;
    width:8.0em;
}

.EditTemplate {
    display:grid;
    grid-template-columns: 9em 1fr 8em;
    grid-column-gap: 2px;
    grid-row-gap: 10px;
    padding:1em;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 5px;
    align-items: top;
    border: 1px solid lightgray;
}

.EditTemplate .Label {
    font-weight:bold;
}

.EditTemplate .SubLabel {
    font-weight:bold;
    margin-bottom:0.5em;
    font-size:1.2em;
}

.EditTemplate .SecondSubLabel {
    font-weight:bold;
    margin-top:0.5em;
    margin-bottom:0.5em;
    font-size:1.2em;
}

.EditTemplate .Description {
    margin-top:0em;
    padding-top:0em;
    grid-column-start: 2;
    grid-column-end: 4;
    margin-bottom: 1.0em;
}

.EditTemplate .Operations {
    grid-column: 3;
    grid-row-start: 1;
    grid-row-end: 6;
    display:grid;
    padding-left:3.5em;
    padding-top:0.2em;
    padding-right:0.0em;
    padding-bottom:0.2em;
    grid-template-columns: 1.0em 1.0em;
    grid-column-gap: 10px;
    font-weight:bold;
    height:1.5em;
}

.EditTemplate .Questions {
    grid-column-start: 2;
    grid-column-end:4;
}

.EditTemplate .Questions .Heading {
    font-size:1.0em;
    font-weight: bold;
    margin:0px;
    position: relative;
}

.EditTemplate .Questions .Remove {
    color: red;
    position:absolute;
    right:0px;
    cursor: pointer;
}

.EditTemplate .Questions .MoveUp {
    cursor: pointer;
    position: absolute;
    cursor:pointer;
    right:1.5em;
}

.EditTemplate .Questions .MoveDown {
    cursor: pointer;
    position: absolute;
    cursor:pointer;
    right:2.5em;
}


.EditTemplate .Questions hr {
    margin-top:0.1em;
    margin-bottom:0.2em;
}

.EditTemplate .Operations .Edit {
    cursor: pointer;
}

.EditTemplate .Operations .Remove {
    color:red;
    cursor: pointer;
}

.EditTemplate h4 {
    margin:0px;
    grid-column-start: 1;
    grid-column-end: 3;
    padding-bottom:15px;
}

.TemplateList .AddQuestion {
    text-align: center;
    font-weight: bold;
    color: black;
    border-radius: 7px;
    font-size:1.0em;
    background-color:$list-header-color;
    padding:2px;
    width:8.0em;
    margin:0px;
}

.TemplateList select {
    margin-bottom:0.2em;
    border-radius: 0px;
}