.RepliesPage {
    display:flex;
    position: relative;
    overflow-x: hidden;
    background-color: #f0f0f0;
    min-height:100vh;
    @media (max-width: 600px) {
        font-size:0.8em;
    }
}

.RepliesPage h3 {
    padding-bottom:0.5em;
}


.RepliesPage .OrganizationLogo {
    margin-left:0.3em;
}

.RepliesPage .QuestionareTree {
    padding-left:1.0em;
    padding-right:1.0em;
    background-color:#f0f0f0;
}

.RepliesPage .ResultPage {
    padding-left:2.0em;
    padding-right:2.0em;
    width:100%;
    min-height:100%;
    margin-bottom:5em;
    flex:70%;
    background-color:#f0f0f0;;
}