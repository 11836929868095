.UiError {
    font-weight: bold;
    font-size: 1.5em;
    max-height:1.2em;
    margin:0px !important;
    padding:0px !important;
    color: #ff0000 !important;
    justify-content: center;
}

.UiNotify {
    color: orange;
    font-size: 1.5em;
}