.QuestionareReplies {
    display:grid;
    grid-template-columns: 1fr;
}

.QuestionareReplies .TotalMetricsContainer {
    grid-column-start: 1;
    grid-column-end: 3;
    min-width:100%;
    padding:0px;
    margin:0px;
}

.QuestionareReplies .EmptyReply { 
    border-radius: 0.5em;
    text-align: center;
    background-color:rgba(192,192,192,0.5);
    margin-top:0.2em;
    margin-bottom:0.1em;
}

.QuestionareReplies .ReplyValue {
    position: relative;
    text-align: center;
    margin-right:0.1em;
    margin-top:0.2em;
    background-color:#efefff;
    border-radius:0.5em;
    flex:1;
    z-index:2;
}


.QuestionareReplies .ReplyValue span {
    position: absolute;
    transform: translate(-50%, -50%);
    font-size:0.7em;
    top: 50%;
}

.QuestionareReplies .Text {
    background-color:#efefff;
    font-weight: normal;
    padding-left:0.5em;
    margin-right:0.1em;
    margin-top:0.2em;
    border-radius:0.5em;
    grid-column: 1;
}

.QuestionareReplies .TextOdd {
    background-color:#dfdfef;
    font-weight: normal;
    padding-left:0.5em;
    margin-right:0.1em;
    margin-top:0.2em;
    grid-column: 1;
}

.QuestionareReplies .OptionPoints {
    background-color:#efefff;
    grid-column-start: 1;
    grid-column-end: 3;
    padding-bottom:0.5em;
    padding-top:0.5em;
}

.QuestionareReplies .OptionPoints .Option {
    background-color:#efefff;
    font-weight: normal;
    margin-right:0.1em;
    margin-top:0.2em;
    border-radius:0.0em;
    font-style:italic;
    border-bottom: grey 1px dotted;
}

.QuestionareReplies .OptionPoints .OptionSelected {
    background-color:#efefff;
    font-weight: bold;
    margin-right:0.1em;
    margin-top:0.2em;
    border-radius:0.0em;
    font-style:italic;
    border-bottom: grey 1px dotted;
    color:black;
}

.QuestionareReplies .OptionPoints .OptionRow {
    display:grid;
    grid-template-columns: 1fr 10.0em 4.0em;
    align-items: flex-end;
}

.QuestionareReplies .OptionPoints .OptionValue {
    font-weight:500;
    color:black;
    background-color:#efefff;
    border-bottom: grey 1px dotted;
    font-size:1.0em;
    margin-top:0.1em;
}

.QuestionareReplies .FullText {
    background-color:#efefff;
    font-weight: normal;
    padding-left:0.8em;
    margin-right:0.1em;
    margin-top:0.2em;
    border-radius:0.5em;
    grid-column: 1;
    grid-column-start: 1;
    grid-column-end: 3;
}

.QuestionareReplies .ResponseText {
    font-weight: normal;
    padding-left:0.5em;
    margin-right:0.1em;
    margin-top:0.2em;
    border-radius:0.5em;
    grid-column: 1;
    font-style:italic;
}

.QuestionareReplies .FullText {
    background-color:#efefff;
    font-weight: normal;
    padding-left:0.5em;
    margin-right:0.1em;
    margin-top:0.2em;
    border-radius:0.5em;
    grid-column-start: 1;
    grid-column-end: 3;
}

.QuestionareReplies .Ka {
    position: relative;
    margin-top:0.2em;
    text-align: center;
    background-color:#efefff;
    border-radius:0.5em;
    flex:1;
}

.QuestionareReplies .Ka span {
    position: absolute;
    transform: translate(-50%, -50%);
    font-size:0.8em;
    top: 50%;
}

.QuestionareReplies .Total {
    margin-top:0.2em;
    text-align: center;
    background-color:#efefff;
    border-radius:0.5em;
}

.QuestionareReplies .Average {
    left:0px;
    min-height:calc(100% - 1px);
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    margin-top:2px;
    margin-bottom:2px;
    background-image: linear-gradient(to right, #efefff, rgba(210,210,250,1.0)); 
}