@import '../../App/colors.scss';

div.ImportUsers {
    position:fixed;
    z-index:101;
    transform: translate(-50%,-50%);
    top:50%;
    left:50%;
    background-color: white;
    box-shadow: 3px 3px 5px 6px rgba(0,0,0,0.2);
    max-width:780px;
    min-width:700px;
    padding:10px;
    margin:5px;
    border-radius: 1.0em;
    max-height:90%;
    min-height:90%;
    grid-template-rows: 2.5em 1fr 2em;
    display:grid;
}

div.ImportUsers .TopBar {
    margin-left:10px;
    margin-top:0px;
    margin-bottom:0px;
    align-items: center;
    color:black;
}

div.ImportUsers .BottomBar {
    display:flex;
    position:absolute;
    bottom:0px;
    padding-right:10px;
    padding-bottom:5px;
    margin-bottom:5px;
    grid-column-gap: 2px;
    flex-direction: row-reverse;
    width:100%;
}

div.ImportUsers .BottomBar button {
    margin-left: 5px;
}

div.ImportUsers .ContentBar {
    padding-top:5px;
    padding-bottom:5px;
    display:grid;
    margin:10px;
    overflow: hidden;
}

div.ImportUsers .ContentColumn {
    overflow-y: auto;
    padding-right: 5px;
}

div.ImportUsers .CloseButton {
    font-weight: 400;
    color: black;
    border-radius: 5px;
    background-color:lightcoral;
    margin-right:2px;
    font-size:1.0em;
    min-width: 80px;
    outline: none;
    border-radius: 5px 0px 0px 5px;
}

div.ImportUsers .ImportButton {
    font-weight: 500;
    color: black;
    border-radius: 0px 5px 5px 0px;
    background-color:$list-header-color;
    font-size:1.0em;
    min-width:80px;
    outline: none;
}

div.ImportUsers .Columns {
    display:grid;
    grid-template-columns: 8em 1fr;
    grid-row-gap: 2px;
    margin-top:0.5em;
}

.ImportUsers h4 {
    margin-top:1em;
}

div.ImportUsers .Columns .ColumnName {
    font-weight:500;
}

div.ImportUsers .Columns .HeaderName {
    margin-bottom:4px;
    font-weight: bold;
}

div.ImportUsers .Users {
    display:grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 2px; 
    margin-top:1em;
}

div.ImportUsers .Users .HeaderName {
    margin-bottom:4px;
    font-weight: bold;
}