@import '../App/colors.scss';

.CircleList .NewCircle input {
    margin-right:2px;
    padding-left:2px;
    font-size:1.0em;
    height:1.8em;
}

.CircleList .NewQuestionButton {
    text-align: center;
    font-weight: normal;
    color: black;
    border-radius: 0px;
    font-size:1.0em;
    background-color:#cfcfcf;;
    align-self: top;
    padding-left:5px;
    padding-right:5px;
    margin-right:2px;
    user-select: none;
}

.CircleList .ItemType {
    padding-left:0.5em;
}

.CircleList .NewCircle {
    display:grid;
    grid-template-columns: 20em 10em 120px;
    margin-top:10px;
    margin-bottom:10px;
}

.CircleList .LibraryOpen {
    font-size:1.0em;
    grid-template-columns: 1.5em 1fr;
    align-items: center;
    display:grid;
    background-color:#cfcfcf;
    color:black;
    padding-left:10px;
    padding-top:2px;
    padding-bottom:2px;
    border-radius:5px 5px 0px 0px;
}

.CircleList .LibraryOpen {
    font-size:1.0em;
    grid-template-columns: 1.5em 1fr;
    align-items: center;
    display:grid;
    background-color:#cfcfcf;
    color:black;
    padding-top:2px;
    padding-bottom:2px;
    margin-top:0.4em;
    margin-bottom: 0px;
    border-radius:5px 5px 0px 0px;
}

.CircleList .LibraryContent {
    padding-left: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    margin:0px;
    background-color: #f0f0f0;
}

.CircleList .NewCircle .NewCircleButton {
    text-align: center;
    font-weight: normal;
    color: white;
    border-radius: 0px;
    font-size:1.0em;
    background-color:green;
    align-self: top;
    padding:0px;
    user-select: none;
}

.CircleList .Circle {

}

.CircleList .Circle .Remove {
    margin-top:0.2em;
    cursor: pointer;
}

.CircleList .Circle .Heading {
    margin-top:0.2em;
    font-size: 1.2em;
    font-weight: bold;
    padding:2px;
    background-color: $list-header-color;
    padding-left:0.7em;
    padding-bottom:2px;
    display: grid;
    grid-template-columns: 1.7em 1fr 1.7em;
    text-align: left;
    margin-right:0.0em;
    border-radius: 5px;
}

.CircleList .Circle .Heading.Level2 {
    margin-top:0.4em;
    font-size: 1.1em;
    font-weight: bold;
    padding:2px;
    background-color: #cfcfff;
    padding-left:0.7em;
    padding-bottom:2px;
    display: grid;
    grid-template-columns: 1.7em 1fr 1.7em;
    text-align: left;
    margin-right:0.0em;
    border: 0.5em;
}

.CircleList .Circle .Heading.Level3 {
    margin-top:0.4em;
    font-size: 1.0em;
    font-weight: bold;
    padding:2px;
    background-color: #bfbfff;
    padding-left:0.7em;
    padding-bottom:2px;
    display: grid;
    grid-template-columns: 1.7em 1fr 1.7em;
    text-align: left;
    margin-right:0.0em;
    border: 0.5em;
}

.CircleList .Circle .Content {
    padding-top:2px;
    padding-left:1.0em;
    padding-right:2px;
    padding-bottom:5px;
    background-color: #fafafa;
    
}

.CircleList .OpenSign {
    margin-top:0.2em;
    color:black;
}

.CircleList .LibraryItem {
    margin-left:0.5em;
    margin-bottom:1.0em;
}

.CircleList .LibraryItem h4 {
    cursor: pointer;
    font-size:1.2em;
    grid-template-columns: 1.5em 1fr 1.5em;
    display:grid;
    align-items: center;
    padding-bottom:3px;
    margin-right:0.5em;
    border-bottom: solid 1px black;
}

.CircleList .LibraryItem .Title {
    margin-top:0.5em;
    margin-bottom:0.1em;
    font-weight: bold;
}

.CircleList .Search {
    display:grid;
    grid-template-rows: 1em 2em;
    margin-bottom:5px;
    margin-left:0px;
    align-items: center;
}

.CircleList .Search div {
    font-size:1.0em;
    font-style:italic;
    padding-left:2px;
    padding-bottom:2px;
}