@import './colors';

:root {
    --bg-color: green;
    --button-bg-color: darkgreen;
    --slide-top-color: '#0faf0f';
    --slide-bottom-color: '#3f715b';
}

@font-face {
  font-family: 'PoppinsRegular';
  src: url('../../public/fonts/Poppins-Regular.otf');
}

@font-face {
  font-family: 'PoppinsRegular';
  src: url('../../public/fonts/Poppins-SemiBold.otf');
  font-weight: bold;
}

* {
  font-family: 'roboto';
}

html, a, div, button {
  font-family: 'roboto' !important;
  font-size:small;
}
body {
  background-color: #f0f0f0;
}

.App {
    min-height:100vh;
    justify-content: center;
}

.App .Main {
  background-color:transparent;
  margin-top:10px;
}

.Button:disabled {
    color: lightgrey;
    border: 1px solid lightgrey;
}

.App .Alerts {
    position: fixed;
    top: 0px;
    left: 0px;
    width:100%;
    z-index:200;
}

.App .Alerts .Alert {
    margin-top:0.1em;
    margin-bottom:0.1em;
    padding-left:15px;
    border-radius:15px;
    grid-column-start: 1;
    grid-column-end: 3;
    background-color:red;
    color:white;
    font-weight: bold;
    font-size: 1.2em;
}

.App .Alerts .Info {
    margin-top:0.1em;
    margin-bottom:0.1em;
    padding-left:15px;
    border-radius:15px;
    grid-column-start: 1;
    grid-column-end: 3;
    background-color:$list-header-color;
    color:black;
    font-weight: bold;
    font-size: 1.2em;
}

.MiniAddButton {
    font-weight: normal;
    color: white;
    border-radius: 10px;
    font-size:0.9em;
    background-color:$list-header-color;
    border: 0px solid white;
}

.MiniRemoveButton {
    font-weight: normal;
    color: white;
    border-radius: 10px;
    font-size:0.9em;
    background-color:red;
    border: 0px solid white;
}

.Checkbox {
    position: absolute;
    opacity: 0;
    & + label {
      position: relative;
      cursor: pointer;
      padding: 0;
    }
  
    // Box.
    & + label:before {
      content: '';
      margin-right: 10px;
      display: inline-block;
      vertical-align: text-bottom;
      width: 20px;
      height: 20px;
      background: white;
      border-radius:5px;
    }
    
    // Box focus
    &:focus + label:before {
      box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
    }
  
    // Box checked
    &:checked + label:before {
      background: white;
    }
    
    // Disabled state label.
    &:disabled + label {
      color: #b8b8b8;
      cursor: auto;
    }
  
    // Disabled box.
    &:disabled + label:before {
      box-shadow: none;
      background: #ddd;
    }
  
    &:checked + label:after {
      content: '';
      position: absolute;
      left: 5px;
      top: 9px;
      background: black;
      width: 2px;
      height: 2px;
      box-shadow: 
        2px 0 0 black,
        4px 0 0 black,
        4px -2px 0 black,
        4px -4px 0 black,
        4px -6px 0 black,
        4px -8px 0 black;
      transform: rotate(45deg);
    }
  }


.Loader,
.Loader:before,
.Loader:after {
  border-radius: 50%;
  width: 1.0em;
  height: 1.0em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
  color:black;
}
.Loader {
  color: black;
  font-size: 10px;
  margin-left: 5.0em;
  margin-top: 0.0em;
  margin-bottom: 2.0em;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.Loader:before,
.Loader:after {
  content: '';
  position: absolute;
  top: 0;
}
.Loader:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.Loader:after {
  left: 3.5em;
}
@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 1.3em 0 -1.3em;
  }
  40% {
    box-shadow: 0 1.3em 0 0;
  }
}
@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 1.3em 0 -1.3em;
  }
  40% {
    box-shadow: 0 1.3em 0 0;
  }
}


button:focus {
  outline: none;
}