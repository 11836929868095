.CircleTree {
    position: relative;
    @media screen and (max-width:1000px) {
        position: absolute;
        z-index:2;
        min-height:100%;
        box-shadow: 0px 0px 5px;
        padding-right:2.0em;
    }
    flex-basis: 30%;
    min-width:400px;
    max-width:400px;
    min-height:100%;
    transition: margin 1.0s;
    margin: 0 0 0 0;
}

.CircleTree.Hide {
    transition: margin 1.0s;
    margin: 0 0 0 -400px;
}

.CircleTree .CloseToggle {
    position:absolute;
    top:5px;
    cursor: pointer;
    color:black;
    font-size:1.5em;
    color:green;
    background-color:white;
    border-radius:50%;
    transition: right 1.0s;
    right: 14px;
}

.CircleTree .CloseToggle.Hide {
    animation: hideToggle-frames 1.0s;
    animation-fill-mode: forwards;
}
@keyframes hideToggle-frames {
    from {
       transform: rotateZ(0deg);
       right: 14px;
    }
    to {
        transform: rotateZ(180deg);
        right: -25px;
    }
}

.CircleTree .CloseToggle.Show {
    animation: showToggle-frames 1.0s;
    animation-fill-mode: forwards;
}
@keyframes showToggle-frames {
    to {
       transform: rotateZ(0deg);
       right: 14px;
    }
    from {
        transform: rotateZ(180deg);
        right: -25px;
    }
}

.CircleTree .Divider {
    position:absolute;
    right:6px;
    top:0px;
    width:6px;
    background-image: linear-gradient(to right, lightgray , white);
    min-height:100vh;
    flex-basis: 5px;
    min-height:100%;
}

.CircleTree .RootFunctions {
    display:grid;
    grid-template-columns: 33% 33%;
    grid-column-gap: 10px;
    position:absolute;
    right:0px;
    top:50%;
    transform: translate(-18%,-40%);
    z-index:3;
}

.CircleTree .OrganizationFunctions {
    display:grid;
    position:absolute;
    grid-template-columns: 33% 33% 33%;
    grid-column-gap: 6px;
    right:5px;
    top:50%;
    transform: translate(-25%,-40%);
    z-index:3;
}

.CircleTree .OrganizationFunctions .Remove {
    cursor: pointer;
    font-size:1.3em;
    color: red;
    background-color:white;
    padding:2px;
    margin-top:-2px;
    margin-left:-2px;
    margin-right:2px;
    border-radius:25%;
}

.CircleTree .OrganizationFunction {
    padding:0px;
    margin-right:5px;
    cursor: pointer;
}

.CircleTree .Root {
    font-size:1.2em;
    font-weight: bold;
    color: white;
    background-color: darkgreen;
    border-top-left-radius: 1em;
    border-top-right-radius: 0.5em;
    border-bottom-left-radius: 0.5em;
    padding-left:2.0em;
    padding-right:1.0em;
    padding-top:8px;
    padding-bottom:8px;
    display:block;
    position: relative;
}

.CircleTree .Root.Create {
    border-radius:0.5em;
}

.CircleTree .Company {
    font-size:1.0em;
    font-weight: bold;
    color: white;
    background-color: green;
    border-top-left-radius: 1em;
    border-bottom-left-radius: 0.5em;
    padding-left:2.0em;
    padding-right:0.1em;
    padding-top:4px;
    padding-bottom:4px;
    display:block;
    position: relative;
}

.CircleTree .Site {
    font-size:1.0em;
    color: white;
    background-color: green;
    border-top-left-radius: 1em;
    border-bottom-left-radius: 1em;
    padding-left:2.0em;
    padding-right:1.0em;
    padding-top:4px;
    padding-bottom:4px;
    display:block;
    position: relative;
}

.CircleTree .School {
    font-size:1.0em;
    font-weight: bold;
    color: white;
    background-color: rgb(3, 125, 105);
    border-top-left-radius: 1em;
    border-bottom-left-radius: 0.5em;
    padding-left:2.0em;
    padding-right:1.0em;
    padding-top:4px;
    padding-bottom:4px;
    display:block;
    position: relative;
}

.CircleTree .Class {
    font-size:1.0em;
    color: white;
    background-color: rgb(3, 125, 155);
    border-top-left-radius: 1em;
    border-bottom-left-radius: 0.5em;
    padding-left:2.0em;
    padding-right:1.0em;
    padding-top:4px;
    padding-bottom:4px;
    display:block;
    position: relative;
}

.CircleTree .Consulting {
    font-size:1.0em;
    font-weight: bold;
    color: white;
    background-color: rgb(160, 0, 99);
    border-top-left-radius: 1em;
    border-bottom-left-radius: 0.5em;
    padding-left:2.0em;
    padding-right:1.0em;
    padding-top:4px;
    padding-bottom:4px;
    display:block;
    position: relative;
}

.CircleTree .System {
    font-size:1.0em;
    font-weight: bold;
    color: white;
    background-color: rgb(128, 128, 128);
    border-top-left-radius: 1em;
    border-bottom-left-radius: 0.5em;
    padding-left:2.0em;
    padding-right:1.0em;
    padding-top:4px;
    padding-bottom:4px;
    display:block;
    position: relative;
}

.CircleTree a {
    color:white;
    position:absolute;
    top:55%;
    transform: translate(-110%,-50%);
}

.CircleTree .Level1 {
    margin-left: 1.5em;
    margin-top: 0.9em;
}

.CircleTree .Level2 {
    margin-top: 0.8em;
    margin-left: 1.5em;
}

.CircleTree .Level3 {
    margin-top: 0.5em;
    margin-left: 1.5em;
    font-size: 0.9em;
}

.CircleTree .Level4 {
    margin-top: 0.8em;
    margin-left: 1.5em;
}

.CircleTree .Open {
    display:block;
}

.CircleTree .OpenSign {
    margin-right:0.3em;
    padding-top:2px;
}

.CircleTree .NoOpenSign {
    color:white;
    position:absolute;
    top:50%;
    font-size:0.7em;
    transform: translate(-180%,-50%);
}

.CircleTree .Closed {
    display:none;
}

.CircleTree .OrganizationLogo {
    margin-left:0.3em;
}

.CircleTree .OpenNode {
    cursor: pointer;
    color:lightgray;
}

.CircleTree .OpenNode:hover {
    color:white;
}

.CircleTree .OpenNodeSelected {
    cursor: pointer;
    text-decoration: underline;
    color:white;
}
