$slide-top-color: #54ad95;
$slide-bottom-color: #489580;

$slide-top-color-dark: #418875;
$slide-bottom-color-dark: #366f5f;

$list-header-color: #c3cad8;

$cauhacare-button-color: #448d7a;
$cauhacare-button-color-darker: #387665;
$cauhacare-button-color-dark: #2a5a4d;