@import '../../App/colors.scss';

div.ThresholdEditor {
    position:fixed;
    z-index:101;
    transform: translate(-50%,-50%);
    top:50%;
    left:50%;
    background-color: white;
    box-shadow: 3px 3px 5px 6px rgba(0,0,0,0.2);
    max-width:500px;
    min-width:500px;
    padding:10px;
    margin:5px;
    border-radius: 1.0em;
    max-height:90%;
    min-height:90%;
    grid-template-rows: 2.5em 1fr 2em;
    display:grid;
}

div.ThresholdEditor .TopBar {
    margin-bottom:20px;
    margin-left:15px;
    margin-top:15px;
    align-items: center;
    color:black;
}

div.ThresholdEditor .BottomBar {
    bottom:0px;
    padding-right:2px;
    margin-right:5px;
    margin-bottom:5px;
    width:100%;
    display:grid;
    grid-template-columns:1fr 6em 9em;
    column-gap: 5px;
}

div.ThresholdEditor .ContentBar {
    padding-top:5px;
    padding-bottom:5px;
    display:grid;
    margin:10px;
    overflow: hidden;
}

div.ThresholdEditor .ContentColumn {
    overflow-y: scroll;
}

div.ThresholdEditor .CloseButton {
    font-weight: 400;
    color: black;
    border-radius: 5px;
    background-color:lightcoral;
    margin-right:2px;
    font-size:1.0em;
    min-width: 80px;
    outline: none;
    border-radius: 5px 0px 0px 5px;
}


div.ThresholdEditor .SetButton {
    font-weight: 500;
    color: black;
    border-radius: 0px 5px 5px 0px;
    background-color:$list-header-color;
    font-size:1.0em;
    min-width:80px;
    outline: none;
}

.ThresholdEditor .Thresholds {
    grid-column-start: 2;
    grid-column-end:4;
    @media screen and (max-width:800px) {
        grid-column-start: 1;
        grid-column-end:4;
    }
}

.ThresholdEditor .Thresholds .Add {
    margin-top:0.5em;
    margin-left:0.4em;
}

.ThresholdEditor .Thresholds .Heading {
    font-size:1.0em;
    font-weight: bold;
    margin:0px;
    position: relative;
}

.ThresholdEditor .Thresholds .Remove {
    color: red;
    position:absolute;
    right:0px;
    cursor: pointer;
}

.ThresholdEditor .Thresholds .ColorLabel {
    font-weight:bold;
    margin-top:0.5em;
}

.ThresholdEditor .Thresholds .Inputs {
    display:grid;
    grid-template-columns: 15em 8em;
    grid-row-gap: 5px;
}

.ThresholdEditor .Thresholds .Inputs input {
    margin-top:0.7em;
    padding:0px;
    max-height:1.8em;
}

.ThresholdEditor .Thresholds hr {
    margin-top:0.1em;
    margin-bottom:0.2em;
}

.Thresholds .Threshold {
    display:grid;
    grid-template-columns: 2em 2em 1fr;
    align-items: center;
}

.Thresholds .Threshold div {
    margin:1px;
}

.Thresholds .EditButton {
    font-weight: normal;
    color: black;
    border-radius: 5px;
    background-color:$list-header-color;
    margin-top:0.2em;
    padding:2px;
    font-size:1.0em;
    min-width:80px;
}
