.ValueNameList {
    margin-top:0.3em;
    margin-bottom:0.3em;
    grid-template-columns: 9em 8em 9em;
    grid-column-gap: 5px;
    display:grid;
    align-items: center;
}

.ValueNameList .NameHeading {
    font-weight:bold;
    font-size:0.9em;
    margin-left:0.2em;
}

.ValueNameList .ValueHeading {
    font-weight:bold;
    font-size:0.9em;
}

.ValueNameList .Name {
    margin-left:0.2em;
    margin-top:0.2em;
    font-size:0.9em;
}

.ValueNameList .Value {
    font-size:0.9em;
}

.ValueNameList .Value input {
    width:9em;
}

.ValueNameList .FunctionsHeading {
    font-size:0.9em;
    font-style:italic;
}

.ValueNameList .Remove {
    color:red;
    cursor: pointer;
}
