@import '../App/colors.scss';

.UserList {
    margin-bottom:2.0em;
}

.EditUser {
    display:grid;
    grid-template-columns: 8em 1fr 8em;
    grid-column-gap: 0px;
    grid-row-gap: 5px;
    padding:8px;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 5px;
    align-items: center;
    border: 1px solid lightgray;
    max-width:45em;
    margin:0px;
}

.EditUserContact {
    display:grid;
    grid-template-columns: 8em 1fr;
    grid-column-gap: 2px;
    padding:8px;
    margin:2px;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 5px;
    align-items: center;
    border: 1px solid lightgray;
}

.EditUserContact h4 {
    grid-column-start: 1;
    grid-column-end: 3;
}
.EditUserContact .Label {
    grid-column-start: 1;
}

.EditUser .Label {
    font-weight:bold;
}

.EditUser .Rights {
    grid-template-columns: 1.2em 1fr;
    grid-row-gap: 1px;
    display:grid;
    margin-bottom:0.4em;
}

.EditUser .Rights .RoleSelector {
    margin-top:0.5em;
    margin-bottom:0.2em;
    grid-column-start: 1;
    grid-column-end: 3;
}

.EditUser .Operations {
    grid-column: 3;
    grid-row-start: 1;
    grid-row-end: 12;
    display:grid;
    padding-left:2.5em;
    padding-top:0.2em;
    padding-bottom:0.2em;
    grid-template-columns: 1.0em 1.0em 2.0em;
    grid-column-gap: 10px;
    font-weight:bold;
    height:1.5em;
}

.EditUser .Operations .Edit {
    cursor: pointer;
}

.EditUser .Invite {
    margin-left: 5px;
    padding-top: 3px;
    cursor: pointer;
}

.EditUser .Operations .Remove {
    color:red;
    cursor: pointer;
}

.EditUser h4 {
    margin:0px;
    grid-column-start: 1;
    grid-column-end: 3;
    padding-bottom:15px;
}

.User {
    display:grid;
    grid-template-columns: 22em 15.0em 8em;
    max-width:45em;
    grid-column-gap: 2px;
    padding:2px;
    align-items: center;
}

.User .IdHeading {
    text-align: center;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 2px;
    background-color: $list-header-color;
    width:2.0em;
    height:2.0em;
    padding-top:0.3em;
    font-weight:bold;
}

.User .Id {
    text-align: center;
    border: 1px solid lightgray;
}

.User .EmailHeading {
    text-align: left;
    background-color: $list-header-color;
    height:2.0em;
    padding-top:0.3em;
    padding-left:0.5em;
    font-weight:bold;
}

.User .Email {
    text-align: left;
    padding-left:0.5em;
    max-width:22em;
    overflow: hidden;
    border: 1px solid lightgray;
}

.User .LatestLogin {
    display:grid;
    grid-template-columns: 1fr 1.3em;
}

.User .NoLatestLogin {
    text-align: left;
    padding-left:0.5em;
    border: 1px solid lightgray;
}


.User .LatestLogin.Success {
    text-align: left;
    padding-left:0.5em;
    padding-top:0.2em;
    border: 1px solid lightgray;
}

.User .LatestLogin.Fail {
    text-align: left;
    padding-left:0.5em;
    border: 1px solid red;
    color: red;
}

.User .LatestLoginHeading {
    text-align: left;
    background-color: $list-header-color;
    height:2.0em;
    padding-top:0.3em;
    padding-left:0.5em;
    font-weight:bold;
}

.User .OperationsHeading {
    text-align: center;
    border-top-right-radius: 5px;
    background-color: $list-header-color;
    font-weight:bold;
    padding-top:0.3em;
    height:2.0em;
}

.User .Operations {
    display:grid;
    padding-left:2.5em;
    padding-top:0.2em;
    grid-template-columns: 1.0em 1.0em;
    grid-column-gap: 10px;
    font-weight:bold;
    height:1.5em;
    border: 1px solid lightgray;
}

.User .Operations .Edit {
    cursor: pointer;
}

.User .Operations .Remove {
    color:red;
    cursor: pointer;
}

.UserList .AddUser {
    margin-top:0.5em;
    color: black;
    border-radius: 10px;
    font-size:0.9em;
    padding-left:8px;
    padding-right:8px;
    padding-top:3px;
    padding-bottom:3px;
    background-color:$list-header-color;
    border: 0px solid white;
}

.UserList .CreateUserRow {
    display:grid;
    grid-template-columns: 2.0em 1fr 8.0em;
    grid-column-gap: 2px;
}

.UserList .User input {
    width:100%;
    border: 1px solid lightgray;
}

.UserList .CreateButton {
    text-align: center;
    font-weight: normal;
    color: white;
    border-radius: 0px;
    font-size:1.0em;
    background-color:green;
    padding:2px;
    margin:0px;
    width:8.0em;
}

.UserList input[type="file"] {
    display:none;
}

.UserList .ImportButton {
    font-size:1.0em;
    padding:2px;
    margin:0px;
    width:12.0em;
    text-transform: none;
}

.UserList .AnonymiseButton {
    font-size:1.0em;
    padding:2px;
    width:8.0em;
    text-transform: none;
    background-color: red;
}

.UserList .AnonymiseButton:hover {
    font-size:1.0em;
    padding:2px;
    text-transform: none;
    background-color: lightcoral;
}

.UserList .ActionButtons {
    display:grid;
    grid-template-columns: 9em 9em 1fr;
}

.User .NewEmail {
    grid-column-start: 1;
    grid-column-end: 3;
}

.User .Label {
    font-size:0.9em;
    font-style:italic;
}

.EditUser .ValueWithIcon {
    display:grid;
    grid-template-columns: 1fr 2.0em;
}

.UserList .Paging {
    display:grid;
    max-width:45em;
    grid-template-columns: 2.0em 8.0em 2.0em;
    align-items: center;
    justify-items: center;
    justify-content: center;
    min-height: 1.8em;
    background-color: $list-header-color;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    margin-top:2px;
}

.UserList .Paging .PagingButton {
    font-size:1.2em;
    cursor: pointer;
}