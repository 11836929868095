.CalendarPage {
    display:flex;
    position: relative;
    overflow-x: hidden;
    background-color: #f0f0f0;
    min-height: 100vh;
}

.CalendarPage h3 {
    padding-bottom:0.5em;
}
