@import '../App/colors.scss';

.OrganizationTree {
    position: relative;
    box-shadow: 0px 0px 3px;
    border-radius: 0px 5px 0px 0px;
    background-color: rgba(255,255,255,0.8);
    @media screen and (max-width:1000px) {
        position: absolute;
        z-index:2;
        min-height:100%;
        box-shadow: 0px 0px 5px;
        padding-right:2.0em;
    }
    min-width:400px;
    max-width:400px;

    @media (max-width: 600px) {
        min-width:250px;
        max-width:250px;
        font-size:0.8em;
    }
    padding-left:1.0em;
    padding-right:2.0em;
    flex-basis: 30%;
    min-height:100%;
    transition: margin 1.0s;
    margin: 0 0 0 0;
}

.OrganizationTree.Hide {
    transition: margin 1.0s;
    margin: 0 0 0 -400px;
    @media (max-width: 600px) {
        margin: 0 0 0 -250px;
    }
}

.OrganizationTree .CloseToggle {
    position:absolute;
    top:10px;
    cursor: pointer;
    color:black;
    font-size:1.5em;
    color:green;
    background-color:white;
    border-radius:50%;
    transition: right 1.0s;
    right: 14px;
}

.OrganizationTree .CloseToggle.Hide {
    animation: hideToggle-frames 1.0s;
    animation-fill-mode: forwards;
}
@keyframes hideToggle-frames {
    from {
       transform: rotateZ(0deg);
       right: 14px;
    }
    to {
        transform: rotateZ(180deg);
        right: -25px;
    }
}

.OrganizationTree .CloseToggle.Show {
    animation: showToggle-frames 1.0s;
    animation-fill-mode: forwards;
}
@keyframes showToggle-frames {
    to {
       transform: rotateZ(0deg);
       right: 14px;
    }
    from {
        transform: rotateZ(180deg);
        right: -25px;
    }
}

.OrganizationTree .Divider {
    position:absolute;
    right:2px;
    top:0px;
    width:6px;
    background-image: linear-gradient(to right, lightgray , white);
    min-height:100vh;
    flex-basis: 5px;
    min-height:100%;
}

.OrganizationTree .RootFunctions {
    display:grid;
    grid-template-columns: 33% 33%;
    grid-column-gap: 10px;
    position:absolute;
    right:0px;
    top:50%;
    transform: translate(-18%,-40%);
    z-index:3;
}

.OrganizationTree .OrganizationFunctions {
    display:grid;
    position:absolute;
    grid-template-columns: 33% 33% 33%;
    grid-column-gap: 6px;
    right:5px;
    top:50%;
    transform: translate(-25%,-40%);
    z-index:3;
}

.OrganizationTree .OrganizationFunctions .Remove {
    cursor: pointer;
    font-size:1.3em;
    color: red;
    background-color:white;
    padding:2px;
    margin-top:-2px;
    margin-left:-2px;
    margin-right:2px;
    border-radius:25%;
}

.OrganizationTree .OrganizationFunction {
    padding:0px;
    margin-right:5px;
    cursor: pointer;
}

.OrganizationTree .Root {
    font-size:1.2em;
    font-weight: bold;
    color: white;
    background-color: $cauhacare-button-color;
    border-top-left-radius: 1em;
    border-top-right-radius: 0.5em;
    border-bottom-left-radius: 0.5em;
    padding-left:2.0em;
    padding-right:1.0em;
    padding-top:8px;
    padding-bottom:8px;
    display:block;
    position: relative;
}

.OrganizationTree .Root.Create {
    border-radius:0.5em;
}

.OrganizationTree .Company {
    font-size:1.0em;
    font-weight: bold;
    color: white;
    background-color: $cauhacare-button-color-darker;
    border-top-left-radius: 1em;
    border-bottom-left-radius: 0.5em;
    padding-left:2.0em;
    padding-right:0.1em;
    padding-top:4px;
    padding-bottom:4px;
    display:block;
    position: relative;
}

.OrganizationTree .Site {
    font-size:1.0em;
    color: white;
    background-color: $cauhacare-button-color-dark;
    border-top-left-radius: 1em;
    border-bottom-left-radius: 1em;
    padding-left:2.0em;
    padding-right:1.0em;
    padding-top:4px;
    padding-bottom:4px;
    display:block;
    position: relative;
}

.OrganizationTree .School {
    font-size:1.0em;
    font-weight: bold;
    color: white;
    background-color:$cauhacare-button-color-darker;
    border-top-left-radius: 1em;
    border-bottom-left-radius: 0.5em;
    padding-left:2.0em;
    padding-right:1.0em;
    padding-top:4px;
    padding-bottom:4px;
    display:block;
    position: relative;
}

.OrganizationTree .Class {
    font-size:1.0em;
    color: white;
    background-color: $cauhacare-button-color-dark;
    border-top-left-radius: 1em;
    border-bottom-left-radius: 0.5em;
    padding-left:2.0em;
    padding-right:1.0em;
    padding-top:4px;
    padding-bottom:4px;
    display:block;
    position: relative;
}

.OrganizationTree .Consulting {
    font-size:1.0em;
    font-weight: bold;
    color: white;
    background-color: rgb(160, 0, 99);
    border-top-left-radius: 1em;
    border-bottom-left-radius: 0.5em;
    padding-left:2.0em;
    padding-right:1.0em;
    padding-top:4px;
    padding-bottom:4px;
    display:block;
    position: relative;
}

.OrganizationTree .System {
    font-size:1.0em;
    font-weight: bold;
    color: white;
    background-color: rgb(128, 128, 128);
    border-top-left-radius: 1em;
    border-bottom-left-radius: 0.5em;
    padding-left:2.0em;
    padding-right:1.0em;
    padding-top:4px;
    padding-bottom:4px;
    display:block;
    position: relative;
}

.OrganizationTree a {
    color:white;
    position:absolute;
    top:55%;
    transform: translate(-110%,-50%);
}

.OrganizationTree .Level1 {
    margin-left: 1.5em;
    margin-top: 0.9em;
}

.OrganizationTree .Level2 {
    margin-top: 0.8em;
    margin-left: 1.5em;
}

.OrganizationTree .Level3 {
    margin-top: 0.5em;
    margin-left: 1.5em;
    font-size: 0.9em;
}

.OrganizationTree .Level4 {
    margin-top: 0.8em;
    margin-left: 1.0em;
}

.OrganizationTree .Level5 {
    margin-top: 0.4em;
    margin-left: 1.0em;
}

.OrganizationTree .Level6 {
    margin-top: 0.2em;
    margin-left: 1.0em;
}

.OrganizationTree .Open {
    display:block;
}

.OrganizationTree .OpenSign {
    margin-right:0.3em;
    padding-top:2px;
}

.OrganizationTree .NoOpenSign {
    color:white;
    position:absolute;
    top:50%;
    font-size:0.7em;
    transform: translate(-180%,-50%);
}

.OrganizationTree .Closed {
    display:none;
}

.OrganizationTree .OrganizationLogo {
    margin-left:0.3em;
}

.OrganizationTree .OpenNode {
    cursor: pointer;
    color:lightgray;
    padding-right: 5.0em;
}

.OrganizationTree .OpenNode:hover {
    color:white;
}

.OrganizationTree .OpenNodeSelected {
    cursor: pointer;
    text-decoration: underline;
    color:white;
    padding-right: 5.0em;
}
