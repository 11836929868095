.TaskEditor {
    position: relative;
}

.TaskEditor .Type {
    display:grid;
    grid-template-columns: 5em 10em;
}

.TaskEditor .BasicInfo {
    margin-top:2px;
    margin-bottom:2px;
    display:grid;
    grid-template-columns: 5em 1fr;
    margin-right:12px;
}

.TaskEditor .Type .Label {
   font-style:italic;
}

.TaskEditor .BasicInfo .Label {
    font-style:italic;
 }

.TaskEditor .NewTaskButton {
    text-align: center;
    font-weight: normal;
    color: black;
    border-radius: 0px;
    font-size:1.0em;
    background-color:#cfcfcf;;
    align-self: top;
    padding-left:5px;
    padding-right:5px;
    margin-right:2px;
    user-select: none;
}

.TaskEditor .ItemEven {
    padding-left:5px;
    padding-right:5px;
    padding-top:2px;
    padding-bottom:4px;
    margin-right:10px;
    margin-bottom:4px;
    background-color:#fafafa;
    border-radius:5px;
}

.TaskEditor .ItemOdd {
    padding-left:5px;
    padding-right:5px;
    padding-top:2px;
    padding-bottom:4px;
    margin-right:10px;
    margin-bottom:4px;
    background-color:#f7f7f7;
    border-radius:5px;
}

.TaskEditor .Delete {
    position:absolute;
    top: 3px;
    right: 15px;
    cursor: pointer;
    color:red;
}