@import '../App/colors.scss';

.MyPage {
    margin:0px;
}

.MyPage .OpenToggleContainer {
    position: absolute;
    top: 0.8em;
    right: 0.5em;
    color: black; 
}

.MyPage .User {
    display:grid;
    position:relative;
    grid-template-columns: 10em 1fr 1em;
    @media (min-width:800px) {
        grid-template-columns: 10em 22em 1fr;        
    }
    grid-column-gap: 2px;
    padding-left:8px;
    padding-right:8px;
    margin:2px;
    min-width:100%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border: 1px solid lightgray;
}

.MyPage .OpenSign {
    color: black;
    cursor: pointer;
}

.MyPage .User h4 {
    grid-column-start: 1;
    grid-column-end: 3;
}

.MyPage .User .Label {
    font-weight:bold;
    font-style:normal;
    font-size:1.0em;
    grid-column: 1;
    margin: 0.3em;
    grid-column-start: 1;
}

.MyPage .EditItem .ResultLabel {
    font-weight:bold;
    margin-bottom:0.2em;
}

.MyPage .EditItem {
    position:relative;
    padding:0.4em;
    grid-column-start: 1;
    grid-column-end: 6;
    display:grid;
    grid-template-columns: 8em 1fr;
}

.MyPage .EditItem .Replies {
    grid-column-start: 1;
    grid-column-end: 3;
}

.MyPage .EditItem .Label {
    font-weight:bold;
}

.MyPage .EditItem .Functions {
    position: absolute;
    top: 7px;
    right: 35px;
}

.MyPage .EditItem .Functions .Edit {
    cursor:pointer;
}

.MyPage .OrganizationHeading {
    font-weight:bold;
    font-style:normal;
    font-size:1.0em;
    grid-column: 1;
    margin-top:1.0em;
    margin-left:0.3em;
    margin-bottom:0.5em;
}

.MyPage .Questionares {
    border: 1px solid lightgray;
    padding-left:8px;
    padding-right:8px;
    margin:2px;
    min-width:100%;
    position: relative;
}

.MyPage .ItemList {
    margin-top:0.5em;
}

.MyPage .Item {
    display:grid;
    grid-template-columns: 1fr 7.0em 12.0em 6.0em;
    grid-column-gap: 2px;
    align-items: center;
}

.MyPage .Item .CreatedHeading {
    text-align: left;
    background-color: $list-header-color;
    height:2.0em;
    padding-top:0.3em;
    padding-left:0.5em;
    font-weight:bold;
}

.MyPage .Item .Created {
    text-align: left;
    border: 1px solid lightgray;
    margin-top:1px;
    margin-bottom:1px;
    padding-left:0.5em;
    overflow: hidden;
    white-space:nowrap;
}

.MyPage .Item .IdHeading {
    text-align: center;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 2px;
    background-color: $list-header-color;
    height:2.0em;
    padding-top:0.3em;
    font-weight:bold;
}

.MyPage .Item .Id {
    text-align: center;
    border: 1px solid lightgray;
}

.MyPage .Item .QuestionareHeading {
    text-align: left;
    background-color: $list-header-color;
    height:2.0em;
    padding-top:0.3em;
    padding-left:0.5em;
    font-weight:bold;
}

.MyPage .Item .QuestionareName {
    text-align: left;
    border: 1px solid lightgray;
    padding-left:0.3em;
    margin-top:1px;
    margin-bottom:1px;
    white-space: nowrap;
    overflow: hidden;
}

.MyPage .Item .ResultHeading {
    text-align: left;
    background-color: $list-header-color;
    height:2.0em;
    padding-top:0.3em;
    padding-left:0.5em;
    font-weight:bold;
}

.MyPage .Item .Result {
    padding-bottom:0px;
    margin:0px;
    margin-top:1px;
    margin-bottom:1px;
    text-align: center;
}

.MyPage .Item .FunctionsHeading {
    text-align: left;
    background-color: $list-header-color;
    height:2.0em;
    padding-top:0.3em;
    padding-left:0.5em;
    font-weight:bold;
    border-top-right-radius: 5px;
}

.MyPage .Item .Functions {
    border: 1px solid lightgray;
    text-align: center;
    align-items: bottom;
    position: relative;
    margin-top:1px;
    margin-bottom:1px;
    height:1.5em;
}

.MyPage .Item .Functions .Edit {
    margin-top:1px;
    cursor: pointer;
}

.MyPage .Item .Functions .Reply {
    margin-top:1px;
    cursor: pointer;
}


.TwoFactorContent {
    display:grid;
    grid-template-columns: 192px 1fr;
}

.TwoFactorContent .Text {
    margin:1.0em;
}

.MyPage .Exercises {
    border: 1px solid lightgray;
    min-width:100%;
    padding:8px;
    margin:2px;
    position: relative;
}

.MyPage .ExerciseList .Exercise .StartButton {
    position: absolute;
    right:1.3em;
    top:1.5em;
}

.MyPage .ExerciseList .Exercise .ReadyText {
    position: absolute;
    right:1.3em;
    top:0.6em;
    border-radius: 5px;
    font-weight: bold;
    font-size:2.0em;
    color:green;
}

.MyPage .ExerciseList .Exercise .Completed {
    position: absolute;
    right:20px;
    top:0.2em;
    color:lightgray;
    border-radius: 5px;
}

.MyPage .ExerciseList .Exercise {
    position: relative;
    display:grid;
    grid-template-columns: 1fr 10em;
    background-image: linear-gradient(to bottom, #fbfbfb ,#f3f3f3);
    padding-left:10px;
    padding-bottom:10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 5px;
}
