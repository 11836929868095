
.WeekdaySelector {
    border-radius: 0px;
    border: 1px solid lightgray;
    appearance: none;
    background-color:white;
    padding-left:0.5em;
    width:15.0em;
}

.IntervalSelector {
    width:12.0em;
    border-radius: 0px;
    margin: 0px;
    border: 1px solid lightgray;
    appearance: none;
    background-color:white;
    padding-left:0.5em;
}

.DateContainer {
    border: 1px solid lightgray;
    max-height: 1.7em;
    padding-left: 0.5em;
    max-width: 11.0em;
}

.DateContainer input {
    border: 0px solid;
    border-radius:0px;
    padding-top:0px;
    padding-bottom:0px;
    padding-left:0px;
    max-width: 11.0em;
    max-height: 1.4em;
}

.LanguageSelector {
    margin-top:0.3em;
    margin-bottom:0.3em;
}

.ColorSelector {
    margin-top:0.2em;
    font-size:2.0em;
}

.ColorSelector .Ball {
    margin-left:0.2em;
    cursor: pointer;
}

.ColorSelector .SelectedBall {
    margin-left:0.2em;
    cursor: pointer;
    border: 2px solid black;
    border-radius:50%;
}

.ParameterTemplateSelector {
    width:12.0em;
    border-radius: 0px;
    margin: 0px;
    border: 1px solid lightgray;
    appearance: none;
    background-color:white;
    padding-left:0.5em;
}