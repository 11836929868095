.LanguageTextEdit {
    margin:0px;
    padding:0px;
    width:100%;
    display:grid;
    grid-template-columns: 1fr 1.5em 1.5em 1.5em 1.5em;
}

.LanguageTextEdit input {
    width:100%;
}

.LanguageTextEdit textarea {
    width:100%;
    margin-bottom:0.2em;
}

.LanguageTextEdit .Label {
    font-style:italic;
}

.LanguageTitleEdit {
    margin:0px;
    padding:0px;
}

.LanguageTitleEdit input {
    width:100%;
}

.LanguageTitleEdit textarea {
    width:100%;
    margin-bottom:0.2em;
}

.LanguageTitleEdit .Label {
    font-style:italic;
}

.LanguageTextEdit .Badge {
    border-radius:50%;
    font-size:0.9em;
    cursor:pointer;
    background-color:black;
    color:white;
    text-align: center;
    margin:1px;
    max-height:1.5em;
}

.LanguageTextEdit .BadgeSelected {
    border-radius:50%;
    font-size:0.9em;
    cursor:pointer;
    background-color:blue;
    color:white;
    text-align: center;
    margin:1px;
    max-height:1.5em;
}

.LanguageTextEdit .BadgeMissing {
    border-radius:50%;
    font-size:0.9em;
    cursor:pointer;
    background-color:red;
    color:white;
    text-align: center;
    margin:1px;
    max-height:1.5em;
}