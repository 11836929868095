.DateTimeComponent {
    display:grid;
    grid-template-columns:1em 1em 1em 1em 1em 1em 1em 1em 1em 1em 1em 2em 1em 1em 1em 1em 1em;
    grid-column-gap: 0px;
    border: 1px solid lightgray;
    border-radius: 5px;
    justify-content: center;
    max-width:18em;
}

.DateTimeComponent .Sign {
    text-align: center;
    align-self: center;
    user-select: none;
}

.DigitEdit {
    display:grid;
    align-items: center;
    justify-items: center;
    grid-template-rows: 0.9em 0.9em 0.9em;   
    grid-row-gap:2px;
    margin:0px;
    padding:0px;
}

.DigitEdit .Value {
    user-select: none;
    padding-left:2px;
    padding-right:2px;
    color:black;
    background-color:#efefef;
    border-radius: 0.5em;
}

.DigitEdit .Tick {
    cursor: pointer;
    margin:0px;
    padding:0px;
    color:#404040;
}

.DateTimeComponent .Day {
    text-align: left;
    margin-left: -3px;
    align-self: center;
    user-select: none;   
}