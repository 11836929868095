
.ConditionEditor {
    display:grid;
    grid-template-columns: 1fr 5em 1fr 1fr 1em;
    column-gap: 0.5em;
    row-gap: 0.5em;
}

.ConditionEditor .Remove {
    font-size:1.4em;
    cursor: pointer;
    color:red;
}

.ConditionEditor .Add {
    grid-column-start: 1;
    grid-column-end: 6;
}

.Condition .Heading {
    grid-column-start: 1;
    grid-column-end: 6;
    margin-top:0.5em;
    margin-bottom:0.3em;
    text-align: left;
    font-weight: bold;
}

.ConditionEditor input {
    max-width:6em;
}

.ConditionHeader {
    display:grid;
    grid-template-columns: 1fr;
    margin-bottom:1.0em;
}

.ConditionHeader .Name {
    font-weight:bold;
    margin-bottom:0.3em;
}