@import '../App/colors.scss';

.ReplyPage {
    position: relative;
    margin-left:auto;
    margin-right:auto;
    min-height:100vh;
    max-width:800px;
    color:black;
    background-color:#f7f7f7;
    padding:1.0em;
    box-shadow: 5px 5px 5px -4px rgba(0,0,0, 0.8), -5px 5px 5px -4px rgba(0,0,0, 0.8);
    padding-bottom:4.0em;
    position:relative;
    border-top-left-radius: 1em;
    border-bottom-right-radius: 1em;
    border-bottom-left-radius: 0em;
    margin-bottom:2.0em;
    padding-top:2.5em;
}

.ReplyPage h3 {
    margin-bottom:1.0em;
}

.ReplyPage .Saved {
    color: green;
    text-align: center;
    font-weight: bold;
}

.ReplyPage .SavedHighlight {
    color: red;
    text-align: center;
    font-weight: bold;
}

.ReplyPage .Question {
    margin-bottom:3.0em;
    margin-top:1.0em;
    padding:0.5em;
    border-top-left-radius: 10px;
}

.ReplyPage .Question .Text {
    margin-top:0.5em;
    margin-bottom:1.5em;
    font-size:1.2em;
    width:100%;
    max-width:98%;
}

.ReplyPage .Question .AgreeNotAgree {
    display:table-row;
    display:flex;
    flex-direction: row;
    flex-basis: fit-content;
    justify-content: center;
    align-content: center;
}

.ReplyPage .Question .AgreeNotAgree .Option {
    display: table-cell;
    text-align: center;
    margin:2px;
    padding:6px;
    width:35px;
    height:35px;
    border-radius: 50%;
    background-color:white;
    border: 1px solid lightgray;
    font-weight: normal;
    font-size:1.2em;
    cursor:pointer;
}

.ReplyPage .Question .AgreeNotAgree .OptionSelected {
    display: table-cell;
    text-align: center;
    margin:2px;
    padding:5px;
    width:35px;
    height:35px;
    border-radius: 50%;
    background-color:$list-header-color;
    font-weight: bold;
    font-size:1.2em;
    border: 2px solid black;
    cursor: pointer;
}

.ReplyPage .Question .AgreeNotAgree .ExplanationLeft {
    display: table-cell;
    align-self: center;
    text-align: right;
    font-style: italic;
    font-size:1.1em;
    margin-right:0.5em;
}


.ReplyPage .Question .AgreeNotAgree .ExplanationRight {
    display: table-cell;
    align-self: center;
    text-align: left;
    font-style: italic;
    font-size:1.1em;
    margin-left:0.5em;
}

.Overlay .Done {
    background-color:rgba(255,255,255,0.7);
    position:fixed;
    top:-15px;
    left:-15px;
    right:-15px;
    bottom:-15px;
    z-index: 2;
}

.Overlay .Thanks {
    min-width:400px;
    border: 2px green solid;
    background-color:white;
    padding-top:7px;
    padding-left:25px;
    padding-right:25px;
    padding-bottom:7px;
    border-top-left-radius: 15px;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 4px;
    border-top-right-radius: 4px;
    top: 50%;
    left: 50%;
    max-height:100%;
    position:fixed;
    overflow-y: scroll;
    transform: translate(-50%,-50%);
    z-index:3;
}

.Overlay .Thanks .Scroll {
    color:black;
    position: relative;
    font-size:2.0em;
    font-weight:bold;
    text-align:left;
}

.Overlay .Thanks .Scroll .Description {
    width:100%;
    text-align:left;
    font-size:1.0em;
}

.Overlay .Thanks .Scroll p {
    font-size:16px !important;
    font-weight:normal;
}

.Overlay .Thanks .Scroll .Login  {
    font-size:0.5em;
}

.Overlay .Thanks .Scroll .Login .Welcome {
    color:green;
}

.Overlay .Thanks .Scroll .Login .Error {
    color:red;
}

.Overlay .Thanks .Scroll .Login  {
    font-size:0.5em;
    font-weight:normal;
}

.Overlay .Thanks .Scroll .Login .ButtonRow {
    margin-top:10px;
    display:grid;
    column-gap: 10px;
    grid-template-columns: 1fr 1fr;
}

.Overlay .Thanks .Scroll .Login .ButtonRowSingle {
    margin-top:10px;
    display:grid;
    grid-template-columns: 1fr;
}

.ReplyPage .SubmitButton {
    left: 50%;
    bottom: 10px;
    position: absolute;
    transform: translate(-50.0%, 0.0%);
}

.ReplyPage .Logo {
    position:absolute;
    top:0px;
    right:0px;
    background-color:#54ad95;
    padding:10px;
    max-width:120px;
    border-bottom-left-radius: 5px;
}

.ReplyPage .KoutsiLogo {
    position:absolute;
    top:0px;
    right:0px;
    max-width:200px;
    border-bottom-left-radius: 5px;
    border-left: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
}

.ReplyPage .Flags {
    position: absolute;
    top: 10px;
    left: 10px;
    display:-ms-grid;
    display:table-row;
    display:grid;
    -ms-grid-columns: 2.0em 2.0em 2.0em 2.0em;
    -ms-grid-gap: 5px;
    grid-template-columns: 2.0em 2.0em 2.0em 2.0em;
}

.ReplyPage .Flags img {
    width:24px;
    cursor: pointer;
}

.ReplyPage .Question .Radio {
    text-align: center;
    margin:5px;
    padding:6px;
    width:20px;
    height:20px;
    border-radius: 50%;
    background-color:white;
    border: 1px solid lightgray;
    font-weight: normal;
    font-size:1.2em;
    cursor:pointer;
}

.ReplyPage .Question .RadioSelected {
    width:20px;
    height:20px;
    margin:5px;
    padding:6px;
    border-radius: 50%;
    font-weight: bold;
    border: 1px solid black;
    cursor: pointer;
    position: relative;
}

.ReplyPage .Question .RadioSelected div {
    background-color:black;
    width:12px;
    height:12px;
    top:50%;
    left:50%;
    border-radius:50%;
    transform: translate(-50%,-50%);
    position: absolute;
}

.ReplyPage .Question .RadioRow {
    padding-left:15px;
    font-style: italic;
    display:grid;
    grid-template-columns: 2.4em 1fr;
    align-items: center;
}

.Shake {
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
  }
  
@keyframes shake {
    10%, 90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%, 80% {
        transform: translate3d(2px, 0, 0);
    }

    30%, 50%, 70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%, 60% {
        transform: translate3d(4px, 0, 0);
    }
}


.FadeOut {
    opacity: 0.3;
}

.Consent {
    margin-top:20px;
    margin-bottom:20px;
    font-size:1.1em;
    display:flex;
}

.ConsentButtons {
    display:grid;
    column-gap: 20px;
    margin-top:20px;
    grid-template-columns: 1fr 1fr;
}

.ConsentContainer {
    margin-top:10em;
    margin-left:auto;
    margin-right:auto;
    max-width: 40em;
}
