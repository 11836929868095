.OrganizationDetails {
    padding-left:2.5em;
    padding-right:2.0em;
    width:100%;
    margin-bottom:5em;
    flex:70%;
    @media (max-width: 600px) {
        font-size:0.8em;
    }
}

.OrganizationDetails button {
    background-color:green;
    border-radius: 10px;
    border: none solid 0px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    margin:2px;
    color: white;
}

.OrganizationDetails .Level1 {
    margin-left: 0.1em;
    margin-right: 0.1em;
    margin-top: 0.9em;
}

.OrganizationDetails .Level2 {
    margin-top: 0.8em;
    margin-left: 1.5em;
    margin-right: 0.1em;
}

.OrganizationDetails .Level3 {
    margin-top: 0.8em;
    margin-left: 1.5em;
    margin-right: 0.1em;
}

.OrganizationDetails .Level4 {
    margin-top: 0.8em;
    margin-left: 1.5em;
    margin-right: 0.1em;
}

.OrganizationDetails .ShowRecursive {
    display:grid;
    grid-template-columns: 2em 1fr;
    padding-bottom: 0.5em;
    margin-left:0.4em;
}

.OrganizationDetails .ParentOrganization {
    font-weight: bold;
    font-size:1.2em;
    margin-top:1.0em;
    margin-bottom:0.2em;
}