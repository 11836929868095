.ExerciseOverlay {
    font-size:1.0em;
}

.ExerciseTitle {   
    font-size:1.4em;
}

.ExerciseItems {
    display: block;
    width: 100%;
}

.ExerciseItems .Item {
    width:100%;
    margin-bottom:1em;
    display:grid;
    grid-template-columns: 1fr 4em;
}

.ExerciseDescription {
    align-self: flex-start;
}

.ExerciseItems .Item .Title {
    font-weight: bold;
    width:100%;
    border-bottom: 1px solid lightgray;
    margin-top:0.8em;
    margin-bottom: 0.9em;
    grid-column-start: 1;
    grid-column-end: 3;
}

.ExerciseItems .Item .Description {
    grid-column-start: 1;
    grid-column-end: 2;
}
