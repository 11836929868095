@import '../App/colors.scss';

.ItemListMatrix {
    margin-top:0.5em;
    font-weight:normal;
}

.ItemListMatrix .Item {
    display:grid;
    grid-template-columns: 15em 1fr 7.0em 3.5em 1fr 2.0em 5.5em;
    grid-column-gap: 2px;
    align-items: center;
}

.ItemListMatrix .ItemSummary {
    display:grid;
    grid-template-columns: 7em 1fr;
    grid-column-gap: 2px;
    align-items: center;
}

.ItemListMatrix .Item .IdHeading {
    text-align: center;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 2px;
    background-color: $list-header-color;
    height:2.0em;
    padding-top:0.3em;
    font-weight:bold;
}

.ItemListMatrix .Item .Id {
    text-align: center;
    border: 1px solid lightgray;
}


.ItemListMatrix .Item .SelectHeading {
    cursor: pointer;
    display: grid;
    grid-template-columns: 1.2em 1fr;
    align-items: center;
    padding-bottom:2px;
}

.ItemListMatrix .Item .NameHeading {
    @extend .SelectHeading;
    text-align: left;
    background-color: $list-header-color;
    height:2.0em;
    padding-top:0.3em;
    padding-left:0.5em;
    font-weight:bold;
    white-space: nowrap;
    overflow: hidden;
}

.ItemListMatrix .Item .Name {
    text-align: left;
    border: 1px solid lightgray;
    padding-left:0.5em;
    white-space: nowrap;
    overflow: hidden;
}

.ItemListMatrix .Item .QuestionareHeading {
    text-align: left;
    background-color: $list-header-color;
    height:2.0em;
    padding-top:0.3em;
    padding-left:0.5em;
    font-weight:bold;
    white-space: nowrap;
    overflow: hidden;
}

.ItemListMatrix .Item .QuestionareName {
    text-align: left;
    border: 1px solid lightgray;
    padding-left:0.3em;
    white-space: nowrap;
    overflow: hidden;
}

.ItemListMatrix .Item .ResultHeading {
    @extend .SelectHeading;
    text-align: left;
    background-color: $list-header-color;
    height:2.0em;
    padding-top:0.3em;
    padding-left:0.5em;
    font-weight:bold;
}

.ItemListMatrix .Item .Result {
    padding-bottom:0px;
    margin:0px;
    text-align: center;
}

.ItemListMatrix .Item .ResultChangeHeading {
    @extend .SelectHeading;
    text-align: left;
    background-color: $list-header-color;
    height:2.0em;
    padding-top:0.3em;
    padding-left:0.5em;
    font-weight:bold;
}

.ItemListMatrix .Item .ResultChange {
    padding-bottom:0px;
    margin:0px;
    text-align: center;
    background-color: lightgray;
    border-radius:0.5em;
}

.ItemListMatrix .Item .CreatedHeading {
    @extend .SelectHeading;
    text-align: left;
    background-color: $list-header-color;
    height:2.0em;
    padding-top:0.3em;
    padding-left:0.5em;
    font-weight:bold;
}

.ItemListMatrix .Item .Created {
    text-align: left;
    border: 1px solid lightgray;
    padding-left:0.5em;
    white-space: nowrap;
    overflow: hidden;
}

.ItemListMatrix .Item .HandlingStatusHeading {
    text-align: left;
    background-color: $list-header-color;
    height:2.0em;
    padding-top:0.3em;
    padding-left:0.2em;
    font-weight:bold;
}

.ItemListMatrix .Item .FunctionsHeading {
    text-align: left;
    background-color: $list-header-color;
    height:2.0em;
    padding-top:0.3em;
    padding-left:0.5em;
    font-weight:bold;
    border-top-right-radius: 5px;
}

.ItemListMatrix .Item .Functions {
    grid-column-start: 7 !important;
    grid-column-end: 8 !important;
    border: 1px solid lightgray;
    text-align: center;
    align-items: bottom;
    padding:0px;
    position: relative;
}

.ItemListMatrix .Item .HandlingStatus {
    grid-column-start: 6 !important;
    grid-column-end: 7 !important;
    border: 1px solid lightgray;
    text-align: center;
    align-items: bottom;
    padding:0px;
    position: relative;
}

.ItemListMatrix .Item .Functions .Edit {
    margin-top:2px;
    cursor: pointer;
}


.ItemListMatrix .EditItem {
    display:grid;
    grid-template-columns: 8em 1fr 6.0em;
    grid-column-gap: 2px;
    grid-row-gap: 3px;
    padding-left:1em;
    padding-top:1em;
    padding-bottom:1em;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 5px;
    align-items: top;
    border: 1px solid lightgray;
    margin-bottom: 0.5em;
}

.ItemListMatrix .EditItem .Functions {
    text-align: center;
    align-items: bottom;
    padding:0px;
    position: relative;
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 12;
}

.ItemListMatrix .EditItem .Functions .Edit {
    margin-top:2px;
    cursor: pointer;
}


.ItemListMatrix .Edititem .Functions .Invite {
    cursor: pointer;
}

.ItemListMatrix .EditItem h4 {
    margin:0px;
    grid-column-start: 1;
    grid-column-end: 3;
    padding-bottom:15px;
}

.ItemListMatrix .EditItem .GroupTitle {
    font-size:1.2em;
    font-weight:bold;
    margin-top:0.4em;
    margin-bottom:0.3em;
}

.ItemListMatrix .EditItem .Label {
    font-weight:bold;
    margin:0px;
    padding:0px;
}

.ItemListMatrix .EditItem .ResultLabel {
    font-weight:bold;
    margin-top:0px;
    margin-bottom:0px;
    padding:0px;
}

.ItemListMatrix .EditItem .Replies {
    font-weight:bold;
    padding:0px;
    @media screen and (max-width: 800px) {
        grid-column: 1;
        grid-column-end: 4;            
    }
}

.ItemListMatrix .EditItem .Bottom {
    min-height:0.5em;
}


.ItemListMatrix .EditItem .ReplyDropDown {
    cursor: pointer;
    margin-top:0.4em;
    padding:2px;
    background-color: #dfdfff;
    padding-left:0.5em;
    padding-bottom:2px;
    display: grid;
    grid-template-columns: 1.7em 1fr;
    align-items: center;
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
}

.ItemListMatrix .EditItem .ReplyDropDownPane {
    padding-top:10px;
    padding-left:5px;
    padding-right:5px;
    padding-bottom:5px;
    background-color: #f0f0ff;
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
}