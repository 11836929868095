@import '../App/colors';

.Screen {
  min-width: 100vw;
  max-width: 100vw;
  min-height: 100vh;
  display:flex;
  position: fixed;
  background-image: url('../../public/images/cauhacare3d.jpg');
  background-size: cover;
}

@media (min-width: 600px) {
    .Background {
      min-width:500px;
      max-width:500px;
      margin:auto;
      background:linear-gradient($slide-top-color-dark, $slide-bottom-color-dark);
      box-shadow: 4px 4px 4px rgba(0,0,0,0.3);
      padding:15px;
      border-top-left-radius:10px;
      border-bottom-right-radius:10px;
      color:white;
      position:relative;
    }
}

@media (max-width: 600px) {
  .Background {
    width:100%;
    min-height: 100vh;
    padding-left:2em;
    padding-right:2em;
    padding-top:calc(50vh - 150px);
    background:linear-gradient($slide-top-color-dark, $slide-bottom-color-dark);
    color:white;
    position:relative;
  }
}

.Background .ResetPasswordLink {
    color: white;
    margin: 18px;
    font-size:1.2em;
    font-style:italic;
    font-weight: normal;
    text-decoration: underline;
}

.Background .Logo {
    position:absolute;
    top:10px;
    right:10px;
    max-width:20%;
}

.Help {
    font-style:italic;
    margin-bottom:4px;
}

.Error {
    margin:10px;
    color:white;
    font-weight: bold;
}

.password-reset-link {
    margin:12px;
}

.Done {
    color: white;
    font-weight: bold;
    margin:10px;
}

.RememberMe {
    margin-top:1.5em;
    margin-bottom:0.5em;
    align-items: center;
    display:flex;
  }


.RememberMe span {
  font-size:1.2em;
}

.Controls {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  max-height:2.5em;
}