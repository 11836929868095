.QuestionareSender {
    display:grid;
    grid-template-columns: 18.0em 18.0em;
    @media screen and (max-width:800px) {
        grid-template-columns: 1fr;        
    }
    grid-column-gap: 20px;
    grid-row-gap: 5px;
    margin-top:0.5em;
    margin-bottom:3.0em;
}

.QuestionareSender input {
    padding:0px;
    box-shadow: none;
    line-height: 0em;
    margin:0em;
    border: 0px solid lightgray;
}

.QuestionareSender .Label {
    font-size: 0.8em;
    font-style:italic;
    margin-bottom:0.2em;
    margin-top:0.3em;
}

.QuestionareSender .SendButton {
    text-align: center;
    font-weight: normal;
    max-width: 18.0em;
    color: white;
    border-radius: 0px;
    font-size:1.0em;
    background-color:green;
    align-self: top;
    max-height:2.8em;
    margin-left:0px;
    margin-right:0px;
    padding:0px;
    margin-top:0.2em;
}

.QuestionareSender .Mediums {
    grid-template-columns: 1.2em 1fr;
    align-items: center;
    display:grid;
    margin-bottom:0.4em;
}