.SystemPage {
    margin: 1em;
}

.SystemPage .Configuration {
    display:grid;
    position:relative;
    grid-template-columns: 12em 20em;
    grid-column-gap: 2px;
    padding:0px 10px 10px 10px;
    margin:2px;
    min-width:100%;
    border-radius: 5px;
    border: 1px solid lightgray;
}

.SystemPage .Configuration h4 {
    grid-column-start: 1;
    grid-column-end: 3;
}

.SystemPage .Configuration .Value input:disabled {
    padding-left:5px;
    background-color:lightgray;
    width: 6em;
}