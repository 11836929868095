.ReplyEditor .Title {
    margin-top:0.1em;
    font-weight:bold;
    margin-bottom:0.1em;
}

.ReplyEditor .Label {
    font-size:0.9em;
    font-weight:normal;
}

.ReplyEditor .CustomScale {
    display:grid;
    grid-template-columns: 7em 1fr;
    grid-column-gap: 2px;
    grid-row-gap: 3px;
    padding:1em;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 5px;
    align-items: top;
    border: 1px solid lightgray;
}

.ReplyEditor .ExcludeFromSums {
    display:grid;
    grid-template-columns: 1.5em 1fr;
    margin-top:0.5em;
    margin-bottom:0.5em;
}

.ReplyEditor .ExcludeFromSums div {
    margin-top:0.1em;
}

.ReplyEditor .OptionPoints {
    margin-top:5px;
}

.ReplyEditor .OptionPoints .Option {
    display:grid;
    grid-template-columns: 20em 4em;
    column-gap: 10px;
}

.ReplyEditor .EnumerationValues {
    margin-top:5px;
    margin-bottom:5px;
}


.ReplyEditor .EnumerationValues .Label {
    margin-bottom: 5px;
    margin-top: 10px;
}

.ReplyEditor .EnumerationValues .Value {
    display:grid;
    grid-template-columns: 1fr 4em 0.5em;
    column-gap: 10px;
    position: relative;
}

.ReplyEditor .EnumerationValues .Value .Delete {
    margin-top: 3px;
    cursor: pointer;
    color:red;
}