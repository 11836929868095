@import '../App/colors.scss';

.ScheduleList {
    margin-bottom:2.0em;
    width:100%;
}

.Schedule {
    display:grid;
    grid-template-columns: 1fr 1fr 1fr 10.0em;
    grid-column-gap: 2px;
    align-items: center;
    padding-bottom:4px;
    position: relative;
}

.ScheduleList .Heading {
    grid-column-start: 1;
    grid-column-end: 4;
    font-weight:bold;
    text-align: left;
}

.Schedule .QuestionareHeading {
    text-align: left;
    background-color: $list-header-color;
    height:2.0em;
    padding-top:0.3em;
    padding-left:0.5em;
    font-weight:bold;
    white-space: nowrap;
    overflow: hidden;
}

.Schedule .Questionare {
    text-align: left;
    white-space: nowrap;
    padding-left:0.5em;
    margin:0px;
    padding-top:0px;
    padding-bottom:0px;
    border: 1px solid lightgray;
    white-space: nowrap;
    overflow: hidden;
}

.Schedule .Questionare .Stopped {
    color:red;
    font-weight:bold;
}

.Schedule .Questionare .Running {
    color:green;
    font-weight:bold;
}

.Schedule .Questionare .Spinning {
    animation-name: spin;
    animation-duration: 3000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear; 
    color:green
}

.Schedule .Questionare .NotSpinning {
    color: red;
    font-size:0.7em;
    margin-left:5px;
    animation: shake 2.0s cubic-bezier(.36,.07,.19,.97) both;
    animation-iteration-count: infinite;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
}

@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}

@keyframes shake {
    2.5%, 22.5% {
      transform: translate3d(-0.5px, 0, 0);
    }
    
    5%, 20% {
      transform: translate3d(0.5px, 0, 0);
    }
  
    7.5%, 12.5%, 17.5% {
      transform: translate3d(-1px, 0, 0);
    }
  
    10%, 20% {
      transform: translate3d(1px, 0, 0);
    }

    50% {
        transform: translate3d(0,0,0);
    }
    100% {
        transform: translate3d(0,0,0);
    }
  }

.Schedule .FirstTimeHeading {
    text-align: left;
    background-color: $list-header-color;
    height:2.0em;
    padding-top:0.3em;
    padding-left:0.5em;
    font-weight:bold;
    white-space: nowrap;
    overflow: hidden;
}

.Schedule .FirstTime {
    text-align: center;
    border: 1px solid lightgray;
    white-space: nowrap;
    overflow: hidden;
}

.Schedule .LastTimeHeading {
    text-align: left;
    background-color: $list-header-color;
    height:2.0em;
    padding-top:0.3em;
    padding-left:0.5em;
    font-weight:bold;
}

.Schedule .LastTime {
    text-align: center;
    border: 1px solid lightgray;
}

.Schedule .OperationsHeading {
    text-align: center;
    border-top-right-radius: 5px;
    background-color: $list-header-color;
    font-weight:bold;
    padding-top:0.3em;
    height:2.0em;
}

.Schedule .Operations {
    display:grid;
    padding-left:2.5em;
    padding-top:0.2em;
    grid-template-columns: 1.0em 1.0em;
    grid-column-gap: 10px;
    font-weight:bold;
    height:1.5em;
    border: 1px solid lightgray;
}

.Schedule .Operations .Edit {
    cursor: pointer;
}

.Schedule .Operations .Remove {
    color:red;
    cursor: pointer;
}

.ScheduleList .CreateRow {
    display:grid;
    grid-template-columns: 18.0em 7.0em;
    grid-column-gap: 4px;
    margin-top:0.5em;
    align-items: center;
}

.ScheduleList .CreateRow input {
    box-shadow: none;
    line-height: 0em;
    margin:0em;
    border: 0px;
}

.ScheduleList .CreateRow .Label {
    font-size: 0.8em;
    font-style:italic;
    margin-bottom:0.2em;
    user-select: none;
}

.ScheduleList .CreateButton {
    text-align: center;
    font-weight: normal;
    color: white;
    border-radius: 0px;
    font-size:1.0em;
    background-color:green;
    align-self: top;
    max-width:10.0em;
    max-height:2.5em;
    min-height: 2.0em;
    padding:0px;
    margin-top:10px;
    margin-left:-2px;
    @media screen and (max-width:800px) {
        grid-column: 1;
    }
    user-select: none;
}

.EditSchedule {
    display:grid;
    grid-template-columns: 9em 1fr;
    grid-column-gap: 2px;
    grid-row-gap: 3px;
    padding:1em;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 5px;
    align-items: center;
    border: 1px solid lightgray;
    margin-bottom: 0.5em;
    position: relative;
}

.EditSchedule h4 {
    margin:0px;
    grid-column-start: 1;
    grid-column-end: 3;
    padding-bottom:15px;
}

.EditSchedule .Label {
    font-weight:bold;
    margin:0px;
    padding:0px;
    user-select: none;
}

.EditSchedule .Date {
    margin-top:0px;
    padding-top:0px;
    max-height:1.0em;
    border: 0px solid lightgray;
}

.EditSchedule .Date input {
    padding:0px;
    box-shadow: none;
    line-height: 0em;
    margin:0em;
    border-radius:0px;
    padding-left:0.5em;
    max-width: 10em;
    border: 1px solid lightgray;
    max-height: 1.5em;
}

.EditSchedule .Reminders {
    display:grid;
    grid-template-columns:150px 30px;
}

.EditSchedule .ReminderRemove {
    cursor: pointer;
}

.EditSchedule .Interval {
    grid-template-columns: 3.0em 10.0em;
    max-height:1.2em;
    display:grid;
}

.EditSchedule .NumberList {
    display:grid;
    grid-template-columns: 10em 2em;
    align-items: center;
    margin-top:2px;
    margin-bottom:2px;
}

.EditSchedule .Repeating {
    grid-template-columns: 2.0em 10.0em;
    grid-row-gap: 5px;
    display:grid;
    margin-bottom:2px;
}

.EditSchedule .DateEraser .Erase {
    transform: translate(-50%, -50%);
}

.EditSchedule .Mediums {
    grid-template-columns: 2.0em 1fr;
    grid-row-gap: 5px;
    display:grid;
    margin-top:0.4em;
    margin-bottom:0.4em;
}

.EditSchedule .Operations {
    grid-column: 3;
    grid-row-start: 1;
    grid-row-end: 15;
    display:grid;
    padding-left:1.7em;
    padding-top:0.2em;
    padding-right:0.0em;
    padding-bottom:0.2em;
    grid-template-columns: 1.0em 1.0em;
    grid-column-gap: 10px;
    font-weight:bold;
    height:1.5em;
}

.EditSchedule .Operations .Remove {
    color:red;
    cursor: pointer;
}

.EditSchedule .Operations .Edit {
    cursor: pointer;
}

.EditSchedule .AdditionalQueries {
    padding:4px 4px 8px 8px;
    display:grid;
    grid-template-columns: 5.0em 1fr;
    column-gap: 2px;
    row-gap: 3px;
    border: 1px solid lightgray;
    border-radius: 5px;
    margin-top:4px;
    position: relative;
}

.EditSchedule .AdditionalQueries .Remove {
    position:absolute;
    top:5px;
    right:5px;
    font-size:1.4em;
    color:red;
    cursor: pointer;
}

.EditSchedule .AdditionalQueries input {
    margin-right:5px;
}

.EditSchedule .DateTimeList {
    display:grid;
    grid-template-columns: 12em 2em;
    align-items: center;
}

.EditSchedule .DateTimeList .Remove {
    cursor: pointer;
    color: red;
    padding-left: 2px;
    font-size:1.4em;
}

.EditSchedule .Condition {
    cursor: pointer;
}