
@import '../App/colors.scss';

.DropDown {
    cursor: pointer;
    margin-top:0.4em;
    font-size: 1.3em;
    font-weight: bold;
    padding:2px;
    background-color: $list-header-color;
    padding-left:0.5em;
    padding-bottom:2px;
    display: grid;
    grid-template-columns: 1.7em 1fr;
    align-items: center;
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
}


.DropDownPane {
    padding-top:10px;
    padding-left:5px;
    padding-right:5px;
    padding-bottom:5px;
    background-color: #ffffff;
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
}