@import '../App/colors.scss';

.QuestionareList {
    margin-bottom:2.0em;
}

.Questionare {
    display:-ms-grid;
    display:grid;
    -ms-grid-columns: 1fr 1fr 8.0em;
    grid-template-columns: 1fr 1fr 8.0em;
    grid-column-gap: 2px;
    align-items: center;
    padding-bottom:4px;
}

.Questionare .IdHeading {
    text-align: center;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 2px;
    background-color: $list-header-color;
    width:2.0em;
    height:2.0em;
    padding-top:0.3em;
    font-weight:bold;
}

.Questionare .Id {
    text-align: center;
    border: 1px solid lightgray;
}

.Questionare .NameHeading {
    text-align: left;
    background-color: $list-header-color;
    height:2.0em;
    padding-top:0.3em;
    padding-left:0.5em;
    font-weight:bold;
    white-space: nowrap;
    overflow: hidden;
}

.Questionare .Name {
    text-align: left;
    border: 1px solid lightgray;
    padding-left:0.5em;
    white-space: nowrap;
    overflow: hidden;
}

.Questionare .GroupHeading {
    text-align: left;
    background-color: $list-header-color;
    height:2.0em;
    padding-top:0.3em;
    padding-left:0.5em;
    font-weight:bold;
}

.Questionare .Group {
    text-align: left;
    border: 1px solid lightgray;
    padding-left:0.5em;
}

.Questionare .NewGroup {
    grid-column-start: 1;
    grid-column-end: 2;
    text-align: left;
    padding-top:0px;
    padding-bottom:0px;
    font-size:1.0em;
    margin-left:0.1em;
    border: 1px solid lightgray;
}

.Questionare .NewGroup input {
    width:100%;
    margin:0px;
    padding:0px;
    border: 0px none lightgray;
}

.Questionare .NewName {
    text-align: left;
    padding-top:0px;
    padding-bottom:0px;
    font-size:1.0em;
    border: 1px solid lightgray;
}

.Questionare .NewName input {
    width:100%;
    margin:0px;
    padding:0px;
    border: 0px none lightgray;
}

.Questionare .OperationsHeading {
    text-align: center;
    border-top-right-radius: 5px;
    background-color: $list-header-color;
    font-weight:bold;
    padding-top:0.3em;
    height:2.0em;
}

.Questionare .Operations {
    display:grid;
    padding-left:2.5em;
    padding-top:0.2em;
    grid-template-columns: 1.0em 1.0em 1.0em;
    grid-column-gap: 10px;
    font-weight:bold;
    height:1.5em;
    border: 1px solid lightgray;
}

.Questionare .Operations .Edit {
    cursor: pointer;
}

.Questionare .Operations .Copy {
    cursor: pointer;
}

.Questionare .Operations .Remove {
    color:red;
    cursor: pointer;
}

.Questionare .AddQuestionare {
    margin-top:0.5em;
    color: black;
    border-radius: 10px;
    font-size:0.9em;
    padding-left:8px;
    padding-right:8px;
    padding-top:3px;
    padding-bottom:3px;
    background-color:$list-header-color;
    border: 0px solid white;
}

.Questionare .Label {
    font-size: 0.8em;
    font-style:italic;
}

.Questionare .GroupLabel {
    grid-column-start: 1;
    grid-column-end: 2;
    font-size: 0.8em;
    font-style:italic;
}

.QuestionareList .CreateQuestionareRow {
    display:grid;
    grid-template-columns: 2.0em 1fr 8.0em;
    grid-column-gap: 2px;
}

.Questionare input {
    width:100%;
    border: 0px none lightgray;
}

.QuestionareList .CreateButton {
    text-align: center;
    font-weight: normal;
    color: white;
    border-radius: 0px;
    font-size:1.0em;
    background-color:green;
    padding:0px;
    margin-left:0px;
    margin-top:5px;
    width:8.0em;
    grid-column: 1;
}

.EditQuestionare {
    display:grid;
    grid-template-columns: 7em 1fr 8em;
    grid-column-gap: 2px;
    grid-row-gap: 3px;
    padding:1em;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 5px;
    align-items: top;
    border: 1px solid lightgray;
    margin-top:0.4em;
    margin-bottom:0.4em;
}

.EditQuestionare .Condition {
    cursor: pointer;
}

.EditQuestionare .Label {
    font-weight:bold;
}

.EditQuestionare .Description {
    margin-top:0em;
    padding-top:0em;
    grid-column-start: 2;
    grid-column-end: 4;
    margin-bottom: 1.0em;
}

.EditQuestionare .Operations {
    grid-column: 3;
    grid-row-start: 1;
    grid-row-end: 6;
    display:grid;
    padding-left:3.5em;
    padding-top:0.2em;
    padding-right:0.0em;
    padding-bottom:0.2em;
    grid-template-columns: 1.0em 1.0em;
    grid-column-gap: 10px;
    font-weight:bold;
    height:1.5em;
}

.EditQuestionare .Questions {
    grid-column-start: 2;
    grid-column-end:4;
    @media screen and (max-width:800px) {
        grid-column-start: 1;
        grid-column-end:4;            
    }
}

.EditQuestionare .Questions .Heading {
    font-size:1.0em;
    font-weight: bold;
    margin:0px;
    position: relative;
    text-align: left;
}

.EditQuestionare .Questions .Heading .Condition {
    cursor: pointer;
}


.EditQuestionare .Questions .RowGap {
    grid-column-start: 1;
    grid-column-end: 5;
    min-height:1.0em;
}

.EditQuestionare .Questions .SubHeading {
    grid-column-start: 1;
    grid-column-end: 4;
    font-weight: bold;
    margin:0px;
    position: relative;
}

.EditQuestionare .Questions .Remove {
    color: red;
    position:absolute;
    right:0px;
    cursor: pointer;
}


.EditQuestionare .Questions .MoveUp {
    cursor: pointer;
    position: absolute;
    cursor:pointer;
    right:1.5em;
}

.EditQuestionare .Questions .MoveDown {
    cursor: pointer;
    position: absolute;
    cursor:pointer;
    right:2.5em;
}


.EditQuestionare .Questions hr {
    margin-top:0.1em;
    margin-bottom:0.2em;
}

.EditQuestionare .Operations .Edit {
    cursor: pointer;
}

.EditQuestionare .Operations .Remove {
    color:red;
    cursor: pointer;
}

.EditQuestionare h4 {
    margin:0px;
    grid-column-start: 1;
    grid-column-end: 3;
    padding-bottom:15px;
}

.QuestionareList .AddQuestion {
    text-align: center;
    font-weight: bold;
    color: black;
    border-radius: 7px;
    font-size:1.0em;
    background-color:$list-header-color;
    padding:2px;
    width:8.0em;
    margin-top:4px;
}

.QuestionareList .AddSubQuestionare {
    text-align: center;
    font-weight: bold;
    color: black;
    border-radius: 7px;
    font-size:1.0em;
    background-color:#9f9fff;
    padding:2px;
    width:9.0em;
    margin-top:2px;
    margin-left:5px;
}

.Questionare select {
    margin:0px;
    padding:0px;
}

.EditQuestionare .Thresholds {
    grid-column-start: 2;
    grid-column-end: 4;
    margin-bottom: 0.5em;
}

.EditQuestionare .Questions {
    margin-bottom:0.5em;
}

.EditQuestionare .Questions .ButtonRow {
    display:grid;
    column-gap: 5px;
    grid-template-columns: 15em 15em 15em;
}

 .EditQuestionare .FeedbackEdit .Heading {
    font-size:1.0em;
    font-weight: bold;
    margin-bottom: 0.5em;
    margin-top: 1.0em;
    position: relative;
    text-align: left;
    border-bottom: 1px solid lightgray;
    border-radius: 0px;
    padding:0px;
    display:grid;
    grid-template-columns: 1fr 1em;
}

.EditQuestionare .FeedbackEdit .Heading .Remove {
    color:red;
    cursor: pointer;
}

.EditQuestionare .FeedbackEdit .Heading .Condition {
    cursor: pointer;
    margin-left:0.2em;
}

.EditQuestionare .FeedbackEdit .HeadingFirst {
    @extend .Heading;
    margin-top:0.0em;
}