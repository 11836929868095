.RadioButton {
    text-align: center;
    font-weight: normal;
    color: black;
    border-radius: 0px;
    font-size:1.0em;
    background-color: #9f9fcf;
    padding-left:1em;
    padding-right:1em;
    padding-top:0.2em;
    padding-bottom:0.2em;
    margin:2px;
    border-radius:1.0em;
    border: none;
    outline: 0;
    min-width:8em;
}


.SelectedRadioButton {
    text-align: center;
    font-weight: normal;
    color: black;
    font-weight:bold;
    border-radius: 0px;
    font-size:1.0em;
    background-color: $list-header-color;
    padding-left:1em;
    padding-right:1em;
    padding-top:0.2em;
    padding-bottom:0.2em;
    margin:2px;
    border-radius:1.0em;
    border: none;
    outline: 0;
    min-width:8em;
}