@import '../../App/colors.scss';

div.CompareResultsContainer {
    overflow:scroll;
    z-index:101;
    background-color: rgba(0,0,0,0.5);
    position:fixed;
    top:0px;
    left:0px;
    bottom:0px;
    right:0px;
    align-content: center;
    justify-content: center;
}

div.CompareResults {
    position: relative;;
    background-color:white;
    box-shadow: 3px 3px 5px 6px rgba(0,0,0,0.2);
    margin:auto;
    width:800px;
    padding:10px;
    border-radius: 1.0em;
    margin-top:20px;
    margin-bottom:30px;
    grid-template-rows: 2.5em 1fr 2em;
    display:grid;
}

.CompareResults .Remove {
    font-size:1.4em;
    cursor: pointer;
    color:red;
}

.CompareResults .BarChart {
    width:500px;
    height:300px;
}

.CompareResults .ReportHeading {
    margin-top:30px !important;
    margin-bottom:20px !important;
}

.CompareResults .Add {
    grid-column-start: 1;
    grid-column-end: 6;
}

.CompareResults input {
    max-width:6em;
}

.CompareResults .BottomBar {
    display:flex;
    position:absolute;
    bottom:0px;
    padding-right:10px;
    padding-bottom:5px;
    margin-bottom:5px;
    grid-column-gap: 2px;
    flex-direction: row-reverse;
    width:100%;
}

.CompareResults .BottomBar button {
    margin-left: 5px;
}

.CompareResults .ContentBar {
    padding-left:2px;
    padding-right:2px;
    padding-top:5px;
    padding-bottom:5px;
    margin-top:20px;
    margin-left:10px;
    margin-right:10px;
    align-content: flex-start;
}

.CompareResults .ContentBar input {
    min-width: 100%;
}

.CompareResults .AddOrganization {
    display: grid;
    grid-template-columns: 1fr 12em 8em;
    grid-column-gap: 15px;
}

.CompareResults .CloseButton {
    font-weight: 400;
    color: black;
    border-radius: 5px;
    background-color:lightcoral;
    margin-right:2px;
    margin-top:5px;
    padding-top:10px;
    font-size:1.0em;
    min-width: 80px;
    outline: none;
    border-radius: 5px 0px 0px 5px;
}

.CompareResults .TopBar {
    margin-left:10px;
    margin-top:0px;
    margin-bottom:20px;
    align-items: center;
    color:black;
}

.CompareResults .BarContainer {
    display:flex;
    flex-direction: column;
    width:100%;
    height:100%;
    position: relative;
}

.CompareResults .Bar {
    width: 80px;
    height: 100px;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    background-color: $list-header-color;
    position: absolute;
    bottom: 0px;
}

.CompareResults .Legend {
    display: grid;
    grid-template-columns: 28px 200px 28px 200px;
    grid-column-gap: 4px;
    margin-bottom:20px;
    margin-left:5px;
    justify-content: left;
}

.CompareResults .Legend .Marker {
    width:24px;
    height:24px;
    border-radius:12px;
    background-color:black;
    margin-bottom: 10px;
    cursor: pointer;
}

.CompareResults .Legend .Text {
    margin-top:2px;
}
