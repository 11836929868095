@import '../App/colors.scss';

div.DialogBackground {
    position:fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background-color: rgba(0,0,0,0.2);
    z-index:100;
}

div.Dialog {
    position:absolute;
    z-index:101;
    transform: translate(-50%,-50%);
    top:50%;
    left:50%;
    background-color: white;
    box-shadow: 3px 3px 5px 6px rgba(0,0,0,0.2);
    min-width:400px;
    min-height:200px;
    padding:0.0em;
    border-radius: 1.0em;
}

div.Dialog h4 {
    margin-bottom:2.0em;
    background-image: linear-gradient(to right, #dfdfff , #bfbfdf);
    padding: 5px 10px 5px 15px;
    border-top-left-radius:15px;
    border-top-right-radius:15px;
    border-bottom-left-radius:4px;
    border-bottom-right-radius:4px;
    margin:1px;
}

div.Dialog .ButtonRow {
    position:fixed;
    bottom:0.5em;
    right:1.0em;
}


div.Dialog .NoButton {
    font-weight: normal;
    color: black;
    border-radius: 5px;
    background-color:lightgreen;
    margin:0.2em;
    font-size:1.1em;
    min-width: 80px;
    font-weight:bold;
}

div.Dialog .YesButton {
    font-weight: normal;
    color: black;
    border-radius: 5px;
    background-color:$list-header-color;
    margin:0.2em;
    font-size:1.1em;
    min-width:80px;
    font-weight:bold;
}

div.Dialog .Content {
    margin:1.0em;
}


div.ErrorDialog {
    position:absolute;
    z-index:101;
    transform: translate(-50%,-50%);
    top:50%;
    left:50%;
    background-color: white;
    box-shadow: 3px 3px 5px 6px rgba(0,0,0,0.2);
    min-width:400px;
    min-height:200px;
    padding:0.0em;
    border-radius: 15px;
}

div.ErrorDialog h4 {
    margin-bottom:2.0em;
    background-image: linear-gradient(to right, rgba(255,192,192, 1.0) , rgba(255,0,0,0.8));
    padding: 5px 10px 5px 15px;
    border-top-left-radius:15px;
    border-top-right-radius:15px;
    border-bottom-left-radius:4px;
    border-bottom-right-radius:4px;
    margin:1px;
}

div.ErrorDialog .Content {
    margin:1.0em;
}

div.ErrorDialog .ButtonRow {
    position:fixed;
    bottom:0.5em;
    right:1.0em;
}

div.ErrorDialog .YesButton {
    font-weight: normal;
    color: black;
    border-radius: 5px;
    background-color:$list-header-color;
    margin:0.2em;
    font-size:1.1em;
    min-width:80px;
    font-weight:bold;
}