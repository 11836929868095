button.SmallAddButton {
    border-radius: "50%" !important;
    width:1.8em;
    height:1.8em;
    padding:5px !important;
    line-height:1.0em !important;
    text-align:center;
    border:none;
    outline:none !important;
    margin:0px !important;
}