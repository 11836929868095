@import '../App/colors.scss';

.RunList {
    margin-top:0.5em;
}

.RunList .RunFunction {
    grid-template-columns: 1fr 15em;
    display:grid;
    padding:0px;
    margin-bottom:5px;
}

.RunList .CombineButton {
    text-align: center;
    font-weight: bold;
    color: black;
    border-radius: 5px;
    font-size:1.0em;
    background-color: $list-header-color;
    align-self: top;
    padding-top:2px;
    padding-bottom:2px;
    user-select: none;
    max-width:8em;
    margin-top:5px;
    margin-bottom:5px;
    outline: 0;
}

.RunList .CombineButton:disabled {
    color:gray;
    background-color:lightgray;
}

.RunList .Report {
    display:grid;
    grid-template-columns: 18em 1.5em;
    column-gap: 10px;
    row-gap: 5px;
    grid-column-start: 2;
    grid-column-end: 4;
    align-items: center;
}

.RunList .Group {
    grid-column-start: 2;
    grid-column-end: 4;
    align-items: left;
}

.RunList .Report .Actions {
    display:grid;
    grid-template-columns: 1fr 1fr;
    grid-column-start: 1;
    grid-column-end: 2;
    column-gap: 5px;
    margin-bottom: 10px;
}

.RunList .Report .Remove {
    cursor: pointer;
    color:red;
    font-size:1.2em;
}

.RunList .Report .RemoveDisabled {
    cursor: default;
    color: gray;
    font-size:1.2em;
}

.RunList .Report button {
    max-height: 2.0em;
    padding-top:3px;
    padding-bottom:3px;
    padding-left:10px;
    padding-right:10px;
    border-radius: 8px;
}

.Run {
    display:grid;
    grid-template-columns: 1fr 1fr 5.0em 5.0em 10.0em 6.0em;
    grid-column-gap: 2px;
    align-items: center;
    padding-bottom:4px;
}

.Run .IdHeading {
    text-align: center;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 2px;
    background-color: $list-header-color;
    height:2.0em;
    padding-top:0.3em;
    font-weight:bold;
}

.Run .Id {
    text-align: center;
    border: 1px solid lightgray;
}

.Run .NameHeading {
    text-align: left;
    background-color: $list-header-color;
    height:2.0em;
    padding-top:0.3em;
    padding-left:0.5em;
    font-weight:bold;
    white-space: nowrap;
    overflow: hidden;
}

.Run .Name {
    text-align: left;
    border: 1px solid lightgray;
    padding-left:0.5em;
    overflow: hidden;
    white-space: nowrap;
}

.Run .OrganizationHeading {
    text-align: left;
    background-color: $list-header-color;
    height:2.0em;
    padding-top:0.3em;
    padding-left:0.5em;
    font-weight:bold;
    white-space: nowrap;
    overflow: hidden;
}

.Run .Organization {
    text-align: left;
    border: 1px solid lightgray;
    padding-left:0.5em;
    white-space: nowrap;
    overflow: hidden;
}

.Run .ResultHeading {
    text-align: left;
    background-color: $list-header-color;
    height:2.0em;
    padding-top:0.3em;
    padding-left:0.5em;
    font-weight:bold;
}

.Run .Result {
    text-align: center;
    padding-top: 2px;
    padding-bottom: 0px;
}

.Run .TypeHeading {
    text-align: left;
    background-color: $list-header-color;
    height:2.0em;
    padding-top:0.3em;
    padding-left:0.5em;
    font-weight:bold;
}

.Run .Type {
    text-align: center;
    border: 1px solid lightgray;
}

.Run .StatusHeading {
    text-align: left;
    background-color: $list-header-color;
    height:2.0em;
    padding-top:0.3em;
    padding-left:0.5em;
    font-weight:bold;
}

.Run .Status {
    text-align: left;
    border: 1px solid lightgray;
    padding-left:0.5em;
}

.Run .CreatedHeading {
    text-align: left;
    background-color: $list-header-color;
    height:2.0em;
    padding-top:0.3em;
    padding-left:0.5em;
    font-weight:bold;
}

.Run .Created {
    text-align: left;
    border: 1px solid lightgray;
    padding-left:0.5em;
}

.Run .FunctionsHeading {
    text-align: left;
    background-color: $list-header-color;
    height:2.0em;
    padding-top:0.3em;
    padding-left:0.5em;
    font-weight:bold;
    border-top-right-radius: 5px;
}

.Run .Functions {
    border: 1px solid lightgray;
    grid-template-columns: 33% 33% 33%;
    display: grid;
    justify-items: center;
    align-items: bottom;
    padding:0px;
    position: relative;
}

.Run .Functions .Edit {
    margin-top:2px;
    cursor: pointer;
}

.Run .Functions .Select {
    margin-bottom:0px;
}

.Run .Functions .Remove {
    color:red;
    padding-top:2px;
    cursor: pointer;
}

.RunList .EditRun {
    display:grid;
    grid-template-columns: 8em 1fr 6.0em;
    grid-column-gap: 2px;
    grid-row-gap: 3px;
    padding:1em;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 5px;
    align-items: top;
    border: 1px solid lightgray;
    margin-bottom: 0.5em;
    position: relative;
}

.RunList .EditRun .Functions {
    grid-template-columns: 1fr 1fr 1fr;
    display: grid;
    justify-items: center;
    align-items: bottom;
    padding:0px;
    position: relative;
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 12;
}

.RunList .EditRun .Functions .Edit {
    margin-top:2px;
    cursor: pointer;
}

.RunList .EditRun .Functions .Select {
    margin-bottom:0px;
}

.RunList .EditRun h4 {
    margin:0px;
    grid-column-start: 1;
    grid-column-end: 3;
    padding-bottom:15px;
}

.RunList .EditRun .GroupTitle {
    font-size:1.2em;
    font-weight:bold;
    margin-top:0.4em;
    margin-bottom:0.3em;
}

.RunList .EditRun .Label {
    font-weight:bold;
    margin-top:0.4em;
    margin-left:0px;
    margin-right:0px;
    margin-bottom:0px;
    padding:0px;
    user-select: none;
}

.RunList .EditRun .InfoLabel {
    font-weight:bold;
    margin-left:0px;
    margin-right:0px;
    margin-bottom:0.4em;
    padding:0px;
    user-select: none;
}

.RunList .EditRun .OrganizationHeading {
    font-weight:bold;
    margin-top:0.4em;
    margin-bottom:0.5em;
    margin-left:0px;
    margin-right:0px;
    margin-bottom:0px;
    padding:0px;
}

.RunList .EditRun .LabelCenter {
    font-weight:bold;
    margin-top:auto;
    margin-bottom:auto;
}

.RunList .EditRun .ResultLabel {
    font-weight:bold;
    margin-top:5px;
    margin-bottom:0px;
    padding:0px;
}

.RunList .EditRun .Replies {
    font-weight:bold;
    padding:0px;
    grid-column-start: 1;
    grid-column-end: 4;
    @media screen and (max-width:800px) {
        grid-column: 1;
        grid-column-end: 4;
    }
}

.RunList .EditRun .Bottom {
    min-height:0.5em;
}

.RunList .SendReminder {
    display:grid;
    grid-template-columns:4.0em 18.0em;
    grid-row-gap: 5px;
    align-items: center;
    margin: 4px 4px 4px 0px;
    padding:8px;
    border-radius: 5px;
}

.RunList .ReminderButton {
    grid-column-start: 1;
    grid-column-end: 3;
}

.RunList .SendReminder .Heading {
    grid-column-start: 1;
    grid-column-end: 3;
    text-align: left;
    font-weight:bold;
    margin-top:0.2em;
}

.RunList .SendReminder .Label {
    grid-column-start: 1;
    grid-column-end: 2;
    font-weight:bold;
}

.RunList .AddReminderTime {
    text-align: center;
    font-weight: bold;
    color: black;
    border-radius: 7px;
    font-size:1.0em;
    background-color:$list-header-color;
    padding:2px;
    width:9.0em;
    margin:0px;
}


.RunList .DropDown {
    cursor: pointer;
    margin-top:0.4em;
    font-size: 1.0em;
    font-weight: bold;
    padding:2px;
    background-color: $list-header-color;
    padding-left:0.5em;
    padding-bottom:2px;
    display: grid;
    grid-template-columns: 1.7em 1fr;
    align-items: center;
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
}


.RunList .DropDownPane {
    padding-top:10px;
    padding-left:5px;
    padding-right:5px;
    padding-bottom:5px;
    background-color: #f0f0f0;
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
}