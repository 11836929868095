@import '../App/colors.scss';

.TotalMetrics {
    display:grid;
    grid-template-columns: 200px 1fr 1fr;
    @media screen and (max-width:900px) {
        grid-template-columns: 1fr;  
        grid-row-gap: 5px;    
        grid-column: 1;  
    }
    margin-top:5px;
    margin-bottom:0px;
}

.TotalMetrics .Heading {
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
    border-bottom-left-radius: 0.0em;
    border-bottom-right-radius: 0.0em;
    padding-left:0.5em;
    background-color: $list-header-color;
    border-color:#ffffff;
    margin-left:0.2em;
    font-weight:bold;
    position: relative;
    text-align: left;
}

.TotalMetrics .Heading .SelectedChart {
    color:black;
    font-size:1.1em;
    margin-left:0.3em;
    transform: translateY(15%);
}

.TotalMetrics .Heading .Chart {
    color:rgba(0,0,0,0.5);
    font-size:1.1em;
    margin-left:0.3em;
    transform: translateY(15%);
}

.TotalMetrics .Pie {
    padding-left:0px;
    padding-top:10px;
    padding-bottom:5px;
    padding-right:0px;
    margin-left:0.2em;
    margin-right:0.1em;
    border: 1px solid $list-header-color;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.TotalMetrics .Descriptions {
    padding-left:10px;
    padding-top:10px;
    padding-bottom:5px;
    padding-right:10px;
    margin-left:0.2em;
    margin-right:0.1em;
    margin-bottom:1.0em;
    border: 1px solid $list-header-color;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    grid-template-columns: 1.5em 4.0em 1fr;
    display:grid;
    align-content: flex-start;
    height:218px;
}

.TotalMetrics .Descriptions .Label {
    border-radius:50%;
    width:1.2em;
    height:1.2em;
    text-align: center;
    transform: translateY(-25%);
}

.TotalMetrics .Descriptions .Points {
    text-align: center;
    min-height:2.0em;
}

.TotalMetrics .DescriptionHeading {
    grid-column-start: 1;
    grid-column-end: 4;
    margin-bottom: 0.4em;
}

.TotalMetrics .History {
    padding-left:0px;
    padding-top:10px;
    padding-bottom:5px;
    padding-right:10px;
    margin-left:0.2em;
    margin-right:0.1em;
    border: 1px solid $list-header-color;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}